import React, { Component } from 'react';
import './css/styleDocs.css';


class PrivacyNotice extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {

        return (
            <div className='priv-container'>
                <div className="privacy-container">
                    <img className="privacy-logo" src="/images/HallmarkMedia_Logo_Plum_RGB.png" alt="Hallmark Channel Logo" />
                </div>
                <div className='b-user_content'>
                    <div className='l-static_page-region'>
                        <div className='l-static_page-item'>
                            <div className="l-grid_layout m-1_up m-centered m-padding_top-md m-padding_bottom-md  m-margin_top-none m-margin_bottom-none " >
                                <div className="l-grid_layout-content ">

                                    <div className="l-grid_layout-item m-lg_12 m-md_12 m-sm_6 m-hide_animation m-margin_bottom-md ">
                                        <div data-cell-type="lg-12 md-12 sm-6" data-layout-type="centered" className="l-grid_layout-item_in">

                                            <div className="b-header_ext m-horizontal_left m-vertical_top">
                                                <div className="b-header_ext-wrapper " style={{ maxWidth: '100%' }}>

                                                    <div className="b-text_block theme-font-color">
                                                        <h1 className="b-heading_2">California Privacy Notice</h1>

                                                        <p style={{ paddingBottom: "10px", paddingTop: "10px" }}>Last Updated: July 24, 2024</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div><div data-cell-type="lg-12 md-12 sm-6" data-layout-type="centered" className="l-grid_layout-item_in">

                                            <div data-slide-type="lg-4 md-4 sm-4" className="b-text_block m-horizontal_left m-vertical_top theme-font-color theme-background-color" style={{ width: '100%' }}>
                                                <div className="b-text_block-container">
                                                    <p style={{ paddingBottom: '10px' }}>This California Privacy Notice (the “Privacy Notice” or “Notice”) provides limited information to California residents only about how Hallmark Marketing Company, LLC, Hallmark Media United States, LLC, Hallmark Business Connections, LLC, and DaySpring Cards, Inc. (collectively, “Hallmark,” “we,” “us,” “our”) collect, use, and disclose personal information. For more details about our privacy practices and any privacy rights you may have, please see our <a className='a-links' href="https://corporate.hallmarkmedia.com/privacy/">Privacy Policy</a>. Neither this Notice nor the Privacy Policy apply to personal information we collect in an employment context, such as information collected from or about job applicants or employees, personal information collected in a business-to-business context, or the privacy practices of affiliated entities that maintain their own privacy policies.</p>

                                                    <h4>Personal Information We Collect</h4>

                                                    <p style={{ paddingBottom: '10px' }}>The types of personal information we collect about you will vary based on how you use our Sites and/or Services (as defined in the Privacy Policy) and your relationship with us, but may include:</p>

                                                    <ul>
                                                        <li><em>Identifiers</em> such as name, shipping address, billing address, email address, phone number, and account information;</li>
                                                        <li><em>Characteristics of protected classifications under California or federal law</em> such as age, date of birth, and gender;</li>
                                                        <li><em>Other demographic information</em> such as the number and ages of children, what language(s) you speak, homeowner status, the number of persons living at the property, household income, marital status;</li>
                                                        <li><em>Commercial information</em> such as bank account number, payment card information, records of Hallmark or third-party products and services purchased or enrolled in, customer type(s), account balance and status, rewards and benefit information, product and service reviews, return and exchange information;</li>
                                                        <li><em>Internet or other electronic network activity information</em> such as IP address, device specifications, browsing and search history, clicks, information about how you interact with our emails and digital advertisements, and other data collected by cookies and similar technologies;</li>
                                                        <li><em>Geolocation data</em> such as non-precise geolocation data collected through a mobile app, your IP address, or otherwise;</li>
                                                        <li><em>Audio, electronic, visual, thermal, olfactory, or similar information</em> such as call recordings, chat transcripts, photos and videos you submit to us, audio files, messages and handwriting, other data provided by you for personalized cards and gifts;</li>
                                                        <li><em>Professional/employment information</em> such as employer, occupation;</li>
                                                        <li><em>Education information</em> such as education level; and</li>
                                                        <li><em>Inferences drawn from other personal information</em> that relate to your preferences, interests, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes. This may include wish lists, watch lists, favorites information, your communications or privacy preferences, and other inferences.</li>
                                                    </ul>

                                                    <p style={{ paddingBottom: '10px' }}>Some of the personal information listed above may be considered sensitive personal information, including your financial account information and account login credentials.</p>

                                                    <h4>How We Use Your Information</h4>

                                                    <p style={{ paddingBottom: '10px' }}>We may use personal information for business purposes, such as:</p>

                                                    <ul>
                                                        <li><em>Providing the Sites and Services:</em> to create customer accounts, allow customers to create online profiles, process payments, respond to requests for information, keep customers informed about the status of their Services, operate rewards programs, and for general account servicing and customer support purposes;</li>
                                                        <li><em>Marketing, market research, and analytics:</em> to market our and third parties’ products and services, perform analytics, and conduct market research;</li>
                                                        <li><em>Other company communications:</em> to otherwise communicate with you about our Sites or Services;</li>
                                                        <li><em>Website analytics:</em> to analyze consumers’ use of our Sites, including the use of cookies and third- party analytics services;</li>
                                                        <li><em>Product research and development:</em> to conduct research and analysis for maintaining, protecting, developing, and improving our Sites and Services;</li>
                                                        <li><em>Business operations:</em> to evaluate, develop, and improve business operations, conduct audits, resolve technical issues, and perform business administration and other normal business activities;</li>
                                                        <li><em>Compliance:</em> to comply with legal requirements, industry standards, contractual obligations, our policies, and take actions that we deem necessary to preserve and enforce our rights and the rights of others;</li>
                                                        <li><em>Information security and fraud prevention:</em> to verify your identity and operate information security and anti-fraud programs; and</li>
                                                        <li><em>Other purposes:</em> as otherwise described to you at the point of collection, with your consent, or as otherwise required by law.</li>
                                                    </ul>

                                                    <p style={{ paddingBottom: '10px' }}>We use sensitive personal information related to California consumers solely for purposes that do not trigger opt-out rights under California Consumer Privacy Act regulations. For example:</p>

                                                    <ul>
                                                        <li><em>Account login credentials</em> – to provide the Sites and Services, conduct web analytics, and operate information security and fraud prevention programs.</li>
                                                        <li><em>Financial account information</em> – to provide the Sites and Services and operate information security and fraud prevention programs.</li>
                                                    </ul>

                                                    <h4>Third-Party Selling and Sharing</h4>

                                                    <p style={{ paddingBottom: '10px' }}>We may disclose personal information, including Identifiers, Other demographic information, Commercial information, and Internet or other electronic network activity information, to third parties in situations that may qualify as a “sale” or “sharing” (for targeted advertising purposes) under California law. We reserve the right to disclose other categories of personal information to other third parties where permitted by law, subject to applicable opt-outs. See our Privacy Policy for more details.</p>

                                                    <p style={{ paddingBottom: '10px' }}>To opt-out of sales or sharing scenarios, please use our Privacy Rights Request Form. To opt-out of third- party data collections from our Sites that may qualify as sales or relate to targeted advertising, you must also (1) opt-out of applicable cookies using the “Cookie Preferences” link in the footer of our Sites or (2) use your browser or a browser extension to send our Sites a <a className='a-links' href="https://globalprivacycontrol.org/" rel="noreferrer noopener" target="_blank">Global Privacy Control</a> or <a className='a-links' href="https://fpf.org/thank-you-for-visiting-allaboutdnt-com/" rel="noreferrer noopener" target="_blank">Do Not Track</a>&nbsp;signal.&nbsp;</p>

                                                    <h4>How Long We Retain Your Personal Information&nbsp;</h4>

                                                    <p style={{ paddingBottom: '10px' }}>We may retain each category of personal information for as long as needed or permitted based on your relationship with us, our legal and regulatory obligations, and normal business purposes,&nbsp;including marketing.&nbsp;</p>




                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div>
        );
    }
}

export default PrivacyNotice;