import React, { Component } from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Navbar, Nav, NavItem } from 'react-bootstrap';
import Home from './Home';
import About from './About';
import Networks from './Networks';
import Responsibility from './Responsibility';
import News from './News';
import Contact from './Contact';
import CareersNew from './components/CareersNew';
import Experience from './components/Experience';
import Internships from './components/Internships';
import ExecutiveBios from './ExecutiveBios';
import Milestones from './Milestones';
import Footer from './components/Footer';
import NavItemWrapper from './components/NavItemWrapper';
import ExecutiveDetails from './components/ExecutiveDetails';
import DiversityCareers from './components/DiversityCareers';
import HistoryCareers from './components/HistoryCareers';
import MakeHerMark from './MakeHerMark';
import MakeHerMarkBio from './MakeHerMarkBio';
import PrivacyNotice from './legal-docs/PrivacyNotice';
import Privacy from './legal-docs/Privacy';
import TextMessagingTerms from './legal-docs/TextMessagingTerms';
import $ from "jquery";
import ContactPage from './ContactPage';
import ScrollToTop from './ScrollToTop';
import TermsOfUse from './legal-docs/TermsOfUse';
import HallmarkPlusTerms from './legal-docs/HallmarkPlusTerms';
import RewardsTermsConditions from './legal-docs/RewardsTermsConditions';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            press: {
                title: "",
                url: ""
            }
        };
    }


    render() {
        const hideNavbarAndFooterPaths = [
            '/privacy-notice/',
            '/privacy-notice',
            '/privacy/',
            '/privacy',
            '/privacy/#financial/',
            '/privacy/#financial',
            '/text-messaging-terms/',
            '/text-messaging-terms',
            '/terms-of-use/',
            '/terms-of-use',
            '/hallmark-plus-terms/',
            '/hallmark-plus-terms',
            '/crown-rewards-terms-conditions/',            
            '/crown-rewards-terms-conditions/'

        ];

        return (
            <Router>
                <Route
                    render={({ location }) => {
                        const shouldHideNavbarAndFooter = hideNavbarAndFooterPaths.includes(location.pathname);

                        return (
                            <>
                                <ScrollToTop>
                                    {!shouldHideNavbarAndFooter && (
                                        <Navbar className='main-color navbar-fixed-top' collapseOnSelect>
                                            <Navbar.Header>
                                                <Navbar.Toggle />
                                            </Navbar.Header>
                                            <Navbar.Collapse>
                                                <Nav className="navbar-center">
                                                    <LinkContainer exact to="/networks"><NavItem>NETWORKS/SERVICES</NavItem></LinkContainer>
                                                    <LinkContainer to="/about-us"><NavItem>ABOUT US</NavItem></LinkContainer>
                                                    <LinkContainer to="/careers"><NavItem>CAREERS</NavItem></LinkContainer>
                                                    <NavItemWrapper link={"/"} img={"/images/HallmarkMedia_Logo_White.png"} />
                                                    <LinkContainer to="/diversity"><NavItem>DIVERSITY & INCLUSION</NavItem></LinkContainer>
                                                    <NavItemWrapper link={"https://press.hallmarkmedia.com"} title={"PRESS"} />
                                                    <LinkContainer to="/makehermark"><NavItem>MAKE HER MARK</NavItem></LinkContainer>
                                                </Nav>
                                            </Navbar.Collapse>
                                        </Navbar>
                                    )}

                                    <div className="content">
                                        <Route exact path="/" component={Home} />
                                        <Route path="/networks" component={Networks} />
                                        <Route exact path="/about-us" component={About} />
                                        <Route exact path="/about-us/executives" component={ExecutiveBios} />
                                        <Route path="/about-us/executives/:id" component={ExecutiveDetails} />
                                        <Route exact path="/about-us/history" component={Milestones} />
                                        <Route path="/about-us/contact" component={Contact} />
                                        <Route exact path="/careers" component={CareersNew} />
                                        <Route path="/careers/opportunities" component={Experience} />
                                        <Route path="/careers/diversity" component={DiversityCareers} />
                                        <Route path="/careers/internships" component={Internships} />
                                        <Route path="/careers/history" component={HistoryCareers} />
                                        <Route path="/diversity" component={Responsibility} />
                                        <Route path="/news" component={News} />
                                        <Route path="/contacts" component={ContactPage} />
                                        <Route exact path="/makehermark" component={MakeHerMark} />
                                        <Route path="/makehermark/bio" component={MakeHerMarkBio} />
                                        {/* Other routes here */}
                                        <Route path="/privacy-notice" component={PrivacyNotice} />
                                        <Route path="/privacy" component={Privacy} />
                                        <Route path="/text-messaging-terms" component={TextMessagingTerms} />
                                        <Route path="/terms-of-use" component={TermsOfUse} />
                                        <Route path="/hallmark-plus-terms" component={HallmarkPlusTerms} />
                                        <Route path="/crown-rewards-terms-conditions" component={RewardsTermsConditions} />
                                    </div>

                                    {!shouldHideNavbarAndFooter && <Footer />}
                                </ScrollToTop>
                            </>
                        );
                    }}
                />
            </Router>
        );
    }
}

export default Main;