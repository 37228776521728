import React, { Component } from 'react';
import './css/styleDocs.css';



class HallmarkPlusTerms extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {

        return (
            <div className='priv-container'>
                <div className="privacy-container">
                    <img className="privacy-logo" src="/images/HallmarkMedia_Logo_Plum_RGB.png" alt="Hallmark Channel Logo" />
                </div>
                <div className='b-user_content'>
                    <div className='l-static_page-region'>
                        <div className='l-static_page-item'>
                            <div className="b-user_content">


                                <div className="l-static_page-region">
                                    <div className="l-static_page-item">

                                        <div className="l-grid_layout m-1_up m-centered m-padding_top-none m-padding_bottom-none  m-margin_top-none m-margin_bottom-none " >
                                            <div className="l-grid_layout-content ">

                                                <div data-items="one" className="l-grid_layout-item m-lg_12 m-md_12 m-sm_6 m-hide_animation m-margin_bottom-none "><div data-cell-type="lg-12 md-12 sm-6" data-layout-type="centered" className="l-grid_layout-item_in">

                                                    <div data-slide-type="lg-4 md-4 sm-4" className="b-text_block m-horizontal_left m-vertical_top theme-font-color theme-background-color" style={{ width: "100%" }}>
                                                        <div className="b-text_block-container">
                                                            <h1 className="b-heading_2">Hallmark+ Supplemental Terms</h1>
                                                            <p style={{ paddingBottom: "10px", paddingTop: "10px" }}>Effective Date: September 10, 2024</p>

                                                        </div>
                                                    </div>
                                                </div>
                                                    <div className="l-static_page-item">

                                                        <div className="l-grid_layout-content ">

                                                            <div data-items="one" className="l-grid_layout-item m-lg_12 m-md_12 m-sm_6 m-hide_animation m-margin_bottom-none "><div data-cell-type="lg-12 md-12 sm-6" data-layout-type="centered" className="l-grid_layout-item_in">

                                                                <div data-slide-type="lg-4 md-4 sm-4" className="b-text_block m-horizontal_center m-vertical_middle theme-font-color theme-background-color" style={{ width: "100%" }}>
                                                                    <div className="b-text_block-container">

                                                                        <p style={{ paddingBottom: '10px' }}>The Hallmark+ Supplemental Terms (“Hallmark+ Terms”) govern your enrollment and membership in Hallmark+ and are entered into between you and Hallmark Media United States, LLC (“Hallmark Media”). The Hallmark+ Terms are incorporated by reference into, and are part of, the <a className='a-links' href="https://corporate.hallmarkmedia.com/terms-of-use/">Hallmark Terms of Use</a> (“TOU”). By enrolling in Hallmark+ (including any trial), you agree to the Hallmark+ Terms, as well as the TOU, which contain an arbitration requirement and a waiver of class-action claims, among other provisions. The Hallmark+ Terms are Supplemental Terms as defined by the TOU.</p>

                                                                    </div>
                                                                </div></div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="m-padding_bottom-md  m-margin_top-none m-margin_bottom-md " >
                                                        <div className="l-grid_layout-content ">

                                                            <div className="l-grid_layout-item m-lg_12 m-md_12 m-sm_6 m-hide_animation m-margin_bottom-none "><div data-cell-type="lg-12 md-12 sm-6" data-layout-type="centered" className="l-grid_layout-item_in">

                                                                <div className="b-accordion" data-widget="accordion" data-allow-toggle="true" data-allow-multiple="true" data-initialized="1">

                                                                    <div className="b-accordion-inner">
                                                                        <div className="b-accordion-items"><div data-widget-event-closeallitems="closeItems" data-id="1-auto-renewal-0" data-widget="accordionItem" data-widget-event-accordionitemupdate="updateFocusedItem" className="b-accordion-item" data-initialized="1">

                                                                            <h3 className="b-accordion-title" >

                                                                                <span>Automatic Renewal; Consent to Recurring Charges.</span>
                                                                                <span className="b-icon_chevron"></span>
                                                                            </h3>
                                                                            <div className="b-accordion-content" id="1-auto-renewal-0" data-ref="accordionItemPanel" aria-labelledby="1-auto-renewal-0-btn" role="region" aria-hidden="true">
                                                                                <div className="b-accordion-content_inner" data-ref="accordionItemPanelInner">
                                                                                    <div className="b-text_block theme-font-color">
                                                                                        <p style={{ paddingBottom: '10px' }}>We may offer Hallmark+ subscriptions that renew automatically until cancelled. If you subscribe to any such subscription, your subscription will automatically renew at the conclusion of the initial subscription period for additional periods of the same duration (or another duration as disclosed to you at the time you enrolled in the subscription) at the then-current fee for such Hallmark+ subscription (plus tax, if applicable) unless you cancel your subscription prior to renewal. You consent to Hallmark Media automatically charging the payment method associated with your account for each renewal period until you cancel your subscription as set forth in Section 4 below, or your subscription is terminated by Hallmark Media in accordance with the TOU or these Hallmark+ Terms.</p>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div><div data-cell-type="lg-12 md-12 sm-6" data-layout-type="centered" className="l-grid_layout-item_in">
                                                            
                                                                    <div className="b-accordion" data-widget="accordion" data-allow-toggle="true" data-allow-multiple="true" data-initialized="1">

                                                                        <div className="b-accordion-inner">
                                                                            <div className="b-accordion-items"><div data-widget-event-closeallitems="closeItems" data-id="2-fees-0" data-widget="accordionItem" data-widget-event-accordionitemupdate="updateFocusedItem" className="b-accordion-item" data-initialized="1">
                                                                                <h3 className="b-accordion-title" >
                                                                                    <button id="2-fees-0-btn" className="b-accordion-button theme-font-color" data-ref="accordionItemBtn" data-event-keydown="handleKeydown" data-event-focus="handleFocus" aria-controls="2-fees-0" type="button" role="button" aria-expanded="false" tabindex="0">
                                                                                        <span>Fees; Payment Terms; Alternate Payment Methods.</span>
                                                                                        <span className="b-icon_chevron"></span>
                                                                                    </button>
                                                                                </h3>
                                                                                <div className="b-accordion-content" id="2-fees-0" data-ref="accordionItemPanel" aria-labelledby="2-fees-0-btn" role="region" aria-hidden="true">
                                                                                    <div className="b-accordion-content_inner" data-ref="accordionItemPanelInner">
                                                                                        <div className="b-text_block theme-font-color">
                                                                                            <p style={{ paddingBottom: "10px" }}>a. You are responsible for payment of the applicable fees for any Hallmark+ subscription in which you enroll. Fees (plus tax, if applicable) will be charged at the time you enroll in the Hallmark+ subscription (or at the conclusion of your free trial (if any), as described Section 6 below) and, for any renewal period, at the time of renewal (at the then-current fee, plus tax if applicable). You may be able to choose different subscription periods (e.g., annual or monthly) and/or different payment plans depending on the Hallmark+ subscription in which you enroll.</p>

                                                                                            <p style={{ paddingBottom: "10px" }}>b. If Hallmark Media does not receive payment from your designated payment method at renewal of your subscription, you authorize Hallmark Media (without notice to you, unless required by applicable law) (i) to retry the payment method on file (including multiple retries); and/or (ii) to collect from any alternate payment methods we have on file for you until full payment is received. You agree that we may either terminate or suspend your subscription until payment is received.</p>

                                                                                            <p style={{ paddingBottom: "10px" }}>c. When you subscribe through our app on a mobile or media-streaming device (e.g., Roku, Apple, Google Play) or as an add-on through a third-party streaming service (e.g., Amazon Prime Video) (any an “In-App Subscription”), your billing relationship will be directly with (and subject to the policies of) the third-party platform, streaming service, app store, or other distributor through which you acquired our app or accessed Hallmark+ (any an “App Store”) and your subscription fees will be billed as an in-app purchase or otherwise charged to your account with the third-party App Store.</p>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div></div>
                                                                        </div>

                                                                    </div>
                                                                </div><div data-cell-type="lg-12 md-12 sm-6" data-layout-type="centered" className="l-grid_layout-item_in"><style>

                                                                </style>

                                                                    <div className="b-accordion" data-widget="accordion" data-allow-toggle="true" data-allow-multiple="true" data-initialized="1">

                                                                        <div className="b-accordion-inner">
                                                                            <div className="b-accordion-items"><div data-widget-event-closeallitems="closeItems" data-id="3-cancellation-0" data-widget="accordionItem" data-widget-event-accordionitemupdate="updateFocusedItem" className="b-accordion-item" data-initialized="1">

                                                                                <h3 className="b-accordion-title" >
                                                                                    <button id="3-cancellation-0-btn" className="b-accordion-button theme-font-color" data-ref="accordionItemBtn" data-event-keydown="handleKeydown" data-event-focus="handleFocus" aria-controls="3-cancellation-0" type="button" role="button" aria-expanded="false" tabindex="0">
                                                                                        <span>Cancellation by You</span>
                                                                                        <span className="b-icon_chevron"></span>
                                                                                    </button>
                                                                                </h3>
                                                                                <div className="b-accordion-content" id="3-cancellation-0" data-ref="accordionItemPanel" aria-labelledby="3-cancellation-0-btn" role="region" aria-hidden="true">
                                                                                    <div className="b-accordion-content_inner" data-ref="accordionItemPanelInner">
                                                                                        <div className="b-text_block theme-font-color">
                                                                                            <p style={{ paddingBottom: "10px" }}>You may cancel your subscription at any time.</p>

                                                                                            <p style={{ paddingBottom: "10px" }}>To cancel a Hallmark+ subscription started through our website, log in, navigate to your account dashboard, and click the “cancel subscription” or similar button. To avoid future charges, you must cancel prior to the end of your then-current billing period.</p>

                                                                                            <p style={{ paddingBottom: "10px" }}>If you have an In-App Subscription to Hallmark+, you must cancel by disabling automatic renewal of your subscription using the procedures established by the applicable App Store. To avoid future charges, you must disable automatic renewal by the deadline set out by the applicable App Store.</p>

                                                                                            <p style={{ paddingBottom: "10px" }}>For any other Hallmark+ subscription, please follow the instructions provided in connection with that subscription or contact us for help. Please see below for contact information.</p>

                                                                                            <p style={{ paddingBottom: "10px" }}>When you cancel your subscription, you may continue to use Hallmark+ until the end of the then-current subscription period for which you’ve previously paid, after which your subscription will expire and your access to Hallmark+ will be terminated.</p>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div></div>
                                                                        </div>

                                                                    </div>
                                                                </div><div data-cell-type="lg-12 md-12 sm-6" data-layout-type="centered" className="l-grid_layout-item_in"><style>

                                                                </style>

                                                                    <div className="b-accordion" data-widget="accordion" data-allow-toggle="true" data-allow-multiple="true" data-initialized="1">

                                                                        <div className="b-accordion-inner">
                                                                            <div className="b-accordion-items"><div data-widget-event-closeallitems="closeItems" data-id="4-cancellation-media-0" data-widget="accordionItem" data-widget-event-accordionitemupdate="updateFocusedItem" className="b-accordion-item" data-initialized="1">

                                                                                <h3 className="b-accordion-title" >
                                                                                    <button id="4-cancellation-media-0-btn" className="b-accordion-button theme-font-color" data-ref="accordionItemBtn" data-event-keydown="handleKeydown" data-event-focus="handleFocus" aria-controls="4-cancellation-media-0" type="button" role="button" aria-expanded="false" tabindex="0">
                                                                                        <span>Cancellation by Hallmark Media</span>
                                                                                        <span className="b-icon_chevron"></span>
                                                                                    </button>
                                                                                </h3>
                                                                                <div className="b-accordion-content" id="4-cancellation-media-0" data-ref="accordionItemPanel" aria-labelledby="4-cancellation-media-0-btn" role="region" aria-hidden="true">
                                                                                    <div className="b-accordion-content_inner" data-ref="accordionItemPanelInner">
                                                                                        <div className="b-text_block theme-font-color">
                                                                                            <p style={{ paddingBottom: "10px" }}>Hallmark Media may discontinue any Hallmark+ subscription (or any features or part thereof) at any time for any or no reason, with or without notice to you. Hallmark Media’s sole obligation to you in the event it exercises such rights will be a pro rata refund for the remainder of your then-current, fully paid-up subscription period. Hallmark Media will not otherwise be liable to you should it exercise such rights, even if your use of or access to Hallmark+ is adversely affected by such modification.</p>

                                                                                            <p style={{ paddingBottom: "10px" }}>If timely payment cannot be charged to your payment method(s) for any reason, if you have materially breached any provision of these Hallmark+ Terms or the TOU, or if Hallmark Media is required to do so by law (e.g., where the provision of Hallmark+ is, or becomes, unlawful), Hallmark Media has the right to, immediately and without notice, block access to, suspend or cancel any Hallmark+ subscription. You agree that all cancellations for cause shall be made in our sole discretion, that we shall not be liable to you or any third party for any suspension or cancellation of your account, and that you will not be entitled to any pro rata refund for the remainder of your then-current subscription.</p>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div></div>
                                                                        </div>

                                                                    </div>
                                                                </div><div data-cell-type="lg-12 md-12 sm-6" data-layout-type="centered" className="l-grid_layout-item_in">

                                                                    <div className="b-accordion" data-widget="accordion" data-allow-toggle="true" data-allow-multiple="true" data-initialized="1">

                                                                        <div className="b-accordion-inner">
                                                                            <div className="b-accordion-items"><div data-widget-event-closeallitems="closeItems" data-id="5-free-trial-0" data-widget="accordionItem" data-widget-event-accordionitemupdate="updateFocusedItem" className="b-accordion-item" data-initialized="1">

                                                                                <h3 className="b-accordion-title" >
                                                                                    <button id="5-free-trial-0-btn" className="b-accordion-button theme-font-color" data-ref="accordionItemBtn" data-event-keydown="handleKeydown" data-event-focus="handleFocus" aria-controls="5-free-trial-0" type="button" role="button" aria-expanded="false" tabindex="0">
                                                                                        <span>Free Trial Subscriptions and Promotional Access</span>
                                                                                        <span className="b-icon_chevron"></span>
                                                                                    </button>
                                                                                </h3>
                                                                                <div className="b-accordion-content" id="5-free-trial-0" data-ref="accordionItemPanel" aria-labelledby="5-free-trial-0-btn" role="region" aria-hidden="true">
                                                                                    <div className="b-accordion-content_inner" data-ref="accordionItemPanelInner">
                                                                                        <div className="b-text_block theme-font-color">
                                                                                            <p style={{ paddingBottom: "10px" }}>a. Free Trials &amp; Introductory Pricing. From time to time, we may offer (in our sole discretion) some or all new or eligible returning subscribers the opportunity to receive a free trial of or introductory pricing for Hallmark+. The terms of the applicable offer will be disclosed to you at the time the offer is made. Free trials and introductory pricing may not be combined with any other offers. You must provide a valid payment method to enroll in a free trial. If you or another member of your household has been a subscriber of Hallmark+ or any other subscription service provided by Hallmark Media (or if your payment method, physical address, or email address have been associated with a subscription for Hallmark+ or any other subscription service provided by Hallmark Media) within the preceding 12 months, you are not eligible to receive a free trial or introductory-price offer, unless otherwise indicated by us. We reserve the right, in our sole discretion, to determine your eligibility for a free trial or introductory price offer.</p>

                                                                                            <p style={{ paddingBottom: "10px" }}>At the conclusion of a free trial or an introductory pricing period, your subscription will automatically renew at the full fee for such Hallmark+ subscription as described above. To avoid being charged (or, for an introductory pricing offer, to avoid additional charges at an increased price), you must cancel before the end of your trial or introductory period.</p>

                                                                                            <p style={{ paddingBottom: "10px" }}>b. Promotional Access Periods. We may, from time to time, offer a promotional period during which non-subscribers may access Hallmark+. At the end of any such promotional period, your access to Hallmark+ will expire and you will need to subscribe and pay the applicable subscription fee to continue using Hallmark+.</p>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div></div>
                                                                        </div>

                                                                    </div>
                                                                </div><div data-cell-type="lg-12 md-12 sm-6" data-layout-type="centered" className="l-grid_layout-item_in">

                                                                    <div className="b-accordion" data-widget="accordion" data-allow-toggle="true" data-allow-multiple="true" data-initialized="1">

                                                                        <div className="b-accordion-inner">
                                                                            <div className="b-accordion-items"><div data-widget-event-closeallitems="closeItems" data-id="6-refunds-and-credits-0" data-widget="accordionItem" data-widget-event-accordionitemupdate="updateFocusedItem" className="b-accordion-item" data-initialized="1">

                                                                                <h3 className="b-accordion-title" >
                                                                                    <button id="6-refunds-and-credits-0-btn" className="b-accordion-button theme-font-color" data-ref="accordionItemBtn" data-event-keydown="handleKeydown" data-event-focus="handleFocus" aria-controls="6-refunds-and-credits-0" type="button" role="button" aria-expanded="false" tabindex="0">
                                                                                        <span>Refunds and Credits</span>
                                                                                        <span className="b-icon_chevron"></span>
                                                                                    </button>
                                                                                </h3>
                                                                                <div className="b-accordion-content" id="6-refunds-and-credits-0" data-ref="accordionItemPanel" aria-labelledby="6-refunds-and-credits-0-btn" role="region" aria-hidden="true">
                                                                                    <div className="b-accordion-content_inner" data-ref="accordionItemPanelInner">
                                                                                        <div className="b-text_block theme-font-color">
                                                                                            <p style={{ paddingBottom: "10px" }}>Except if we discontinue Hallmark+ entirely as set forth in Section 4 above, we do not provide refunds or credits for any unused portions of Hallmark+, or unused/unwatched Hallmark+ benefits/content. If you cancel your subscription, you will retain access to Hallmark+ for the remainder of the billing period for which you have paid but will not receive a refund. Any refunds, if issued (in our sole discretion), will be issued to the payment method used to pay for the Hallmark+ subscription.</p>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div></div>
                                                                        </div>

                                                                    </div>
                                                                </div><div data-cell-type="lg-12 md-12 sm-6" data-layout-type="centered" className="l-grid_layout-item_in">

                                                                    <div className="b-accordion" data-widget="accordion" data-allow-toggle="true" data-allow-multiple="true" data-initialized="1">

                                                                        <div className="b-accordion-inner">
                                                                            <div className="b-accordion-items">
                                                                                <div data-widget-event-closeallitems="closeItems" data-id="7-changes-media-0" data-widget="accordionItem" data-widget-event-accordionitemupdate="updateFocusedItem" className="b-accordion-item" data-initialized="1">
                                                                                <h3 className="b-accordion-title" >
                                                                                    <button id="7-changes-media-0-btn" className="b-accordion-button theme-font-color" data-ref="accordionItemBtn" data-event-keydown="handleKeydown" data-event-focus="handleFocus" aria-controls="7-changes-media-0" type="button" role="button" aria-expanded="false" tabindex="0">
                                                                                        <span>Changes by Hallmark Media</span>
                                                                                        <span className="b-icon_chevron"></span>
                                                                                    </button>
                                                                                </h3>
                                                                                <div className="b-accordion-content" id="7-changes-media-0" data-ref="accordionItemPanel" aria-labelledby="7-changes-media-0-btn" role="region" aria-hidden="true">
                                                                                    <div className="b-accordion-content_inner" data-ref="accordionItemPanelInner">
                                                                                        <div className="b-text_block theme-font-color">
                                                                                            <p style={{ paddingBottom: "10px" }}>We reserve the right to change the price of Hallmark+ at any time in our sole discretion. For existing subscribers, we will notify you of any impending price change in advance. If you do not agree to the price change, you may terminate your subscription prior to that change taking effect.</p>

                                                                                            <p style={{ paddingBottom: "10px" }}>We may introduce, change, or remove features, services, benefits, content, and/or materials to/from Hallmark+ at any time in our sole discretion. New or additional features, services, benefits, content, and/or materials may be a part of, or may be priced separately from, existing levels of subscription at our sole discretion.</p>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div></div>
                                                                        </div>

                                                                    </div>
                                                                </div><div data-cell-type="lg-12 md-12 sm-6" data-layout-type="centered" className="l-grid_layout-item_in">
                                                                                                                        
                                                                    <div className="b-accordion" data-widget="accordion" data-allow-toggle="true" data-allow-multiple="true" data-initialized="1">

                                                                        <div className="b-accordion-inner">
                                                                            <div className="b-accordion-items"><div data-widget-event-closeallitems="closeItems" data-id="8-additional-usage-terms-0" data-widget="accordionItem" data-widget-event-accordionitemupdate="updateFocusedItem" className="b-accordion-item" data-initialized="1">

                                                                                <h3 className="b-accordion-title" >
                                                                                    <button id="8-additional-usage-terms-0-btn" className="b-accordion-button theme-font-color" data-ref="accordionItemBtn" data-event-keydown="handleKeydown" data-event-focus="handleFocus" aria-controls="8-additional-usage-terms-0" type="button" role="button" aria-expanded="false" tabindex="0">
                                                                                        <span>Additional Usage Terms</span>
                                                                                        <span className="b-icon_chevron"></span>
                                                                                    </button>
                                                                                </h3>
                                                                                <div className="b-accordion-content" id="8-additional-usage-terms-0" data-ref="accordionItemPanel" aria-labelledby="8-additional-usage-terms-0-btn" role="region" aria-hidden="true">
                                                                                    <div className="b-accordion-content_inner" data-ref="accordionItemPanelInner">
                                                                                        <div className="b-text_block theme-font-color">
                                                                                            <p style={{ paddingBottom: "10px" }}>a. Cable Subscription Access Not Included. An In-App Subscription to Hallmark+ (for example, subscriptions through Roku, Apple, Amazon Fire, or Google Play) or a subscription through our website does not entitle you to authenticate or log-in to any subscription or payment-based Hallmark service offered through cable TV or similar streaming TV providers (such as on-demand or MVPD subscription services to the Hallmark Channel or Hallmark+).;</p>

                                                                                            <p style={{ paddingBottom: "10px" }}>b. Cable-Based Subscription Limits. Conversely, if you purchase a subscription to Hallmark+ through a cable TV or similar streaming TV provider, that purchase does not allow you to authenticate or log-in to our apps or the website version of Hallmark+.</p>

                                                                                            <p style={{ paddingBottom: "10px" }}>c. Viewing Limits. You may access Hallmark+ on up to five (5) different computers or devices associated with your account, but you may view Hallmark+ only on a maximum of two (2) different devices simultaneously.</p>

                                                                                            <p style={{ paddingBottom: "10px" }}>d. Streaming. The resolution and quality of any stream of Hallmark+ depends on several factors beyond Hallmark Media’s control, including the bandwidth of your Internet connection and the type of device you are using. As a result, Hallmark Media cannot make any guarantees regarding the resolution or quality of Hallmark+ content that you stream.</p>

                                                                                            <p style={{ paddingBottom: "10px" }}>e. No Guarantee of Continuing Availability. Any Hallmark+ title or other content may become unavailable at any time and for any reason. Hallmark Media will have no liability to you if any title/content becomes unavailable for further streaming.</p>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div></div>
                                                                        </div>

                                                                    </div>
                                                                </div><div data-cell-type="lg-12 md-12 sm-6" data-layout-type="centered" className="l-grid_layout-item_in">

                                                                    <div className="b-accordion" data-widget="accordion" data-allow-toggle="true" data-allow-multiple="true" data-initialized="1">

                                                                        <div className="b-accordion-inner">
                                                                            <div className="b-accordion-items"><div data-widget-event-closeallitems="closeItems" data-id="9-cr-integration-0" data-widget="accordionItem" data-widget-event-accordionitemupdate="updateFocusedItem" className="b-accordion-item" data-initialized="1">

                                                                                <h3 className="b-accordion-title" >
                                                                                    <button id="9-cr-integration-0-btn" className="b-accordion-button theme-font-color" data-ref="accordionItemBtn" data-event-keydown="handleKeydown" data-event-focus="handleFocus" aria-controls="9-cr-integration-0" type="button" role="button" aria-expanded="false" tabindex="0">
                                                                                        <span>Crown Rewards Integration</span>
                                                                                        <span className="b-icon_chevron"></span>
                                                                                    </button>
                                                                                </h3>
                                                                                <div className="b-accordion-content" id="9-cr-integration-0" data-ref="accordionItemPanel" aria-labelledby="9-cr-integration-0-btn" role="region" aria-hidden="true">
                                                                                    <div className="b-accordion-content_inner" data-ref="accordionItemPanelInner">
                                                                                        <div className="b-text_block theme-font-color">
                                                                                            <p style={{ paddingBottom: "10px" }}>Crown Rewards is a loyalty program offered by Hallmark Marketing Company, LLC (“Hallmark Marketing”) providing points and rewards for its members. Membership in Crown Rewards is governed by the <a className='a-links' href="https://corporate.hallmarkmedia.com/terms-of-use/">TOU</a> and the <a className='a-links' href="https://corporate.hallmarkmedia.com/crown-rewards-terms-conditions/">Crown Rewards Program Supplemental Terms</a> (“Crown Rewards Terms”).</p>

                                                                                            <p style={{ paddingBottom: "10px" }}>If you have a Crown Rewards membership, your Crown Rewards membership must be linked with your Hallmark+ subscription to receive the Extra Benefits outlined below. If you do not have a Crown Rewards membership at the time you subscribe to Hallmark+, you will automatically be enrolled in Crown Rewards (exceptions may apply). You may cancel your Crown Rewards membership at any time by calling 1-800-HALLMARK; however, please be aware that certain subscription benefits that require a Crown Rewards membership will not be available to you as explained more fully in Section 10.</p>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div></div>
                                                                        </div>

                                                                    </div>
                                                                </div><div data-cell-type="lg-12 md-12 sm-6" data-layout-type="centered" className="l-grid_layout-item_in">
                                                                    <div className="b-accordion" data-widget="accordion" data-allow-toggle="true" data-allow-multiple="true" data-initialized="1">

                                                                        <div className="b-accordion-inner">
                                                                            <div className="b-accordion-items"><div data-widget-event-closeallitems="closeItems" data-id="10-extra-benefits-0" data-widget="accordionItem" data-widget-event-accordionitemupdate="updateFocusedItem" className="b-accordion-item" data-initialized="1">
                                                                                <h3 className="b-accordion-title" >
                                                                                    <button id="10-extra-benefits-0-btn" className="b-accordion-button theme-font-color" data-ref="accordionItemBtn" data-event-keydown="handleKeydown" data-event-focus="handleFocus" aria-controls="10-extra-benefits-0" type="button" role="button" aria-expanded="false" tabindex="0">
                                                                                        <span>Extra Benefits</span>
                                                                                        <span className="b-icon_chevron"></span>
                                                                                    </button>
                                                                                </h3>
                                                                                <div className="b-accordion-content" id="10-extra-benefits-0" data-ref="accordionItemPanel" aria-labelledby="10-extra-benefits-0-btn" role="region" aria-hidden="true">
                                                                                    <div className="b-accordion-content_inner" data-ref="accordionItemPanelInner">
                                                                                        <div className="b-text_block theme-font-color">
                                                                                            <p style={{ paddingBottom: '10px' }}>a. Benefit Availability. Hallmark+ subscribers who purchased through subscription via a Hallmark Media website or application, and who have a Crown Rewards membership, are eligible to receive the benefits described in Section 10(b) (“Extra Benefits”), below, during the term of their Hallmark+ subscription. For clarity, Hallmark+ subscribers who have an In-App Subscription are ineligible for the Extra Benefits. Certain benefits may require logging in to the Hallmark.com website or the Hallmark Cards Now application with the subscriber’s Crown Rewards membership to use. All benefits may be subject to additional terms and conditions presented with the benefit. The subscriber is responsible for any taxes, shipping, handling, and other fees that may relate to a purchase for which a benefit is used.</p>

                                                                                            <p style={{ paddingBottom: '10px' }}>b. Benefit Descriptions</p>

                                                                                            <ol role="list" start="1" style={{ marginLeft: "40px" }}>
                                                                                                <li>
                                                                                                    <p style={{ paddingBottom: '10px' }}>Unlimited Digital eCards. Subscriber may send unlimited eCards per month through the Hallmark.com website or through the Hallmark Cards Now application.</p>
                                                                                                </li>
                                                                                            </ol>

                                                                                            <ol role="list" start="2" style={{ marginLeft: "40px" }}>
                                                                                                <li>
                                                                                                    <p style={{ paddingBottom: '10px' }}>Free Physical Card Coupon. Subscriber will receive one coupon per month for the free purchase of a physical greeting card at a Hallmark Gold Crown Store. Each coupon may only be used during the month it is issued and expires at the end of that month. Coupons do not rollover month-to-month. Eligible greeting cards may be limited as indicated on the coupon.</p>
                                                                                                </li>
                                                                                            </ol>

                                                                                            <ol role="list" start="3" style={{ marginLeft: "40px" }}>
                                                                                                <li>
                                                                                                    <p style={{ paddingBottom: '10px' }}>Free Custom Greeting Card Coupon. Subscriber will receive one coupon per month for the free purchase of a custom greeting card on the Hallmark.com website or Hallmark Cards Now application. Each coupon may only be used during the month it is issued and expires at the end of that month. Coupons do not rollover month-to-month. Eligible print-on-demand greeting cards may be limited as indicated on the coupon.</p>
                                                                                                </li>
                                                                                            </ol>

                                                                                            <ol role="list" start="4" style={{ marginLeft: "40px" }}>
                                                                                                <li>
                                                                                                    <p style={{ paddingBottom: '10px' }}>Crown Rewards Membership. Upon discontinuation of the subscriber’s Hallmark+ subscription, the subscriber’s Crown Rewards membership will revert to a standard membership unless the subscriber is otherwise eligible for a Platinum membership under the <a className='a-links' href="https://corporate.hallmarkmedia.com/crown-rewards-terms-conditions/">Crown Rewards Terms</a>. If subscriber wishes to cancel his or her Crown Rewards membership, they may do so by calling 1-800-HALLMARK.</p>
                                                                                                </li>
                                                                                            </ol>

                                                                                            <ol role="list" start="5" style={{ marginLeft: "40px" }}>
                                                                                                <li>
                                                                                                    <p style={{ paddingBottom: '10px' }}>Bonus Crown Rewards Points. Subscriber will receive a total of an additional 50% more points on every dollar spent using their Crown Rewards membership. This benefit is inclusive of the 25% points bonus from Platinum Crown Rewards membership and is not in addition thereto. Please see the <a className='a-links' href="https://corporate.hallmarkmedia.com/crown-rewards-terms-conditions/">Crown Rewards Terms</a> for additional information regarding point-eligible purchases, redemption options, and other important conditions and limitations.</p>
                                                                                                </li>
                                                                                            </ol>

                                                                                            <ol role="list" start="6" style={{ marginLeft: "40px" }}>
                                                                                                <li>
                                                                                                    <p style={{ paddingBottom: '10px' }}>Free Keepsake Ornament. If the subscriber has an annual Hallmark+ subscription, the subscriber will receive one coupon on or about August of each year for the free purchase of one Hallmark Keepsake Ornament, available for redemption at a Hallmark Gold Crown Store, on the Hallmark.com website, or in the Hallmark Cards Now application. The coupon must be used by its expiration date. Eligible ornaments may be limited as indicated on the coupon.</p>
                                                                                                </li>
                                                                                            </ol>

                                                                                            <ol role="list" start="7" style={{ marginLeft: "40px" }}>
                                                                                                <li>
                                                                                                    <p style={{ paddingBottom: '10px' }}>Free Birthday Gift. If the subscriber has an annual Hallmark+ subscription, the subscriber will be eligible for one coupon for a gift of Hallmark Marketing Company’s choosing each year. The coupon will be available for redemption at a Hallmark Gold Crown Store, on the Hallmark.com website, or in the Hallmark Cards Now application. The coupon must be used within 30 days of the subscriber’s birthday and does not rollover year-to-year. A subscriber’s birthday will be based upon the birthday for that subscriber in Hallmark Marketing Company’s records. Must add birthday to your Hallmark profile and be an active Hallmark+ member 5 days before actual self-reported birth date.</p>
                                                                                                </li>
                                                                                            </ol>

                                                                                            <ol role="list" start="8" style={{ marginLeft: "40px" }}>
                                                                                                <li>
                                                                                                    <p style={{ paddingBottom: '10px' }}>New Member Welcome Gift. If a subscriber enrolls in an annual Hallmark+ subscription for the first time, the subscriber will receive one coupon within 30 days of their enrollment for a gift of Hallmark Marketing Company’s choosing, available for redemption at a Hallmark Gold Crown Store, on the Hallmark.com website, or in the Hallmark Cards Now application. The coupon must be used by its expiration date. This benefit is available to first time Hallmark+ subscribers only. If the subscriber or another member of the subscriber’s household has been a subscriber of Hallmark+ (or if the subscriber’s payment method, physical address, or email address have been associated with a subscription for Hallmark+) in the past, that subscriber is not eligible to receive the welcome gift.</p>
                                                                                                </li>
                                                                                            </ol>

                                                                                            <ol role="list" start="9" style={{ marginLeft: "40px" }}>
                                                                                                <li>
                                                                                                    <p style={{ paddingBottom: '10px' }}>$5 Monthly Coupon. Subscriber will receive one coupon per month for $5 off an eligible purchase at a Hallmark Gold Crown Store, on the Hallmark.com website, or in the Hallmark Cards Now application. Each coupon may only be used during the month it is issued and expires at the end of that month. Coupons do not rollover month-to-month. Coupon may not be used toward the purchase of gift cards or the purchase or renewal of a Hallmark+ subscription. Eligible products may be further limited as indicated on the coupon.</p>
                                                                                                </li>
                                                                                            </ol>

                                                                                            <ol role="list" start="10" style={{ marginLeft: "40px" }}>
                                                                                                <li>
                                                                                                    <p style={{ paddingBottom: '10px' }}>Crown Rewards Points for Viewing. From time to time, Hallmark Media may announce opportunities to earn Crown Rewards points for taking certain actions such as watching a particular movie or series. Points may take up to 90 days to be reflected in the subscriber’s Crown Rewards account after taking the action needed to earn the points. These opportunities may be content and time limited as announced.</p>
                                                                                                </li>
                                                                                            </ol>

                                                                                            <ol role="list" start="11" style={{ marginLeft: "40px" }}>
                                                                                                <li>
                                                                                                    <p style={{ paddingBottom: '10px' }}>30% Off Custom Invitations, Announcements and Holiday Photo Cards. Subscribers will receive 30% off the purchase of custom invitations, announcements, and holiday photo cards purchased on the Hallmark.com website. This discount may be combined with other coupons and offers.</p>
                                                                                                </li>
                                                                                            </ol>

                                                                                            <ol role="list" start="12" style={{ marginLeft: "40px" }}>
                                                                                                <li>
                                                                                                    <p style={{ paddingBottom: '10px' }}>Early Access or Discount Special Offers. From time to time, Hallmark Media may announce special offers for subscribers to gain early access and/or discounts to certain products, services, or events. These offers will be subject to additional terms and conditions as announced.</p>
                                                                                                </li>
                                                                                            </ol>

                                                                                            <p style={{ paddingBottom: '10px' }}>c. Limited Benefits Without Crown Rewards Membership. If a Hallmark+ subscriber who is otherwise eligible to receive the Extra Benefits cancels their Crown Rewards membership or does not enroll in Crown Rewards, such subscriber will no longer be eligible for Extra Benefits except (i) unlimited digital eCards and (ii) 30% discount off purchase of custom invitations, announcements, and holiday photo cards.</p>

                                                                                            <p style={{ paddingBottom: '10px' }}>d. Changes to Extra Benefits. Hallmark Media may update, change, discontinue, or add Extra Benefits at any time with notice to subscribers by (i) posting a notice on the Hallmark Media website or (ii) sending an email to active subscribers eligible for Extra Benefits at the time of the change.</p>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div></div>
                                                                        </div>

                                                                    </div>
                                                                </div><div data-cell-type="lg-12 md-12 sm-6" data-layout-type="centered" className="l-grid_layout-item_in">
                                                                    <div className="b-accordion" data-widget="accordion" data-allow-toggle="true" data-allow-multiple="true" data-initialized="1">

                                                                        <div className="b-accordion-inner">
                                                                            <div className="b-accordion-items"><div data-widget-event-closeallitems="closeItems" data-id="11-contact-us-0" data-widget="accordionItem" data-widget-event-accordionitemupdate="updateFocusedItem" className="b-accordion-item" data-initialized="1">

                                                                                <h3 className="b-accordion-title" >
                                                                                    <button id="11-contact-us-0-btn" className="b-accordion-button theme-font-color" data-ref="accordionItemBtn" data-event-keydown="handleKeydown" data-event-focus="handleFocus" aria-controls="11-contact-us-0" type="button" role="button" aria-expanded="false" tabindex="0">
                                                                                        <span>Contact Us</span>
                                                                                        <span className="b-icon_chevron"></span>
                                                                                    </button>
                                                                                </h3>
                                                                                <div className="b-accordion-content" id="11-contact-us-0" data-ref="accordionItemPanel" aria-labelledby="11-contact-us-0-btn" role="region" aria-hidden="true">
                                                                                    <div className="b-accordion-content_inner" data-ref="accordionItemPanelInner">
                                                                                        <div className="b-text_block theme-font-color">
                                                                                            <p style={{ paddingBottom: "10px" }}>If you have any questions regarding these Hallmark+ Terms or need help, please contact us at: <a className='a-links' href="mailto:care@hallmarkplus.com" rel="noreferrer noopener" target="_blank">care@hallmarkplus.com</a> or 1-844-446-5669.</p>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div >


                    </div >
                </div >


            </div>
        );
    }
}

export default HallmarkPlusTerms;