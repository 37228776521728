import React, { Component } from 'react';
import Slideshow from './components/Slideshow';
import MakeHerMarkArrow from './MakeHerMarkArrow';
import MakeHerMarkForm from './MakeHerMarkForm'


class MakeHerMark extends Component {
    constructor(props) {
        super(props);
        this.state = {
            body: "",
            slides: [
                { ImagePath: "CC24_HM_MakeHerMark_Banner_1440x560_v1.jpg" },
                { ImagePath: "CC24_HM_CorpWebSlideshow_MHMBnrs_1440x560_f_v4.jpg" },
                { ImagePath: "CC24_HM_CorporateCommSlideshow_MakeHerMarkBanner_JQ_Final.jpg" },
                { ImagePath: "CC24_HM_CorpWebSlideshow_MHMBnrs_1440x560_f_v1.jpg" },
                { ImagePath: "CC24_HM_CorpWebSlideshow_MHMBnrs_1440x560_f_v2.jpg" },
                { ImagePath: "CC24_HM_CorpWebSlideshow_MHMBnrs_1440x560_f_v3.jpg" },
            ],
            id: [
                { id: "#mhmAbout", title: "ABOUT" },
                { id: "#mhmFaq", title: "FAQ" },
                { id: "#mhmApply", title: "APPLY" },
            ],
            selectedLink: ""
        };

        this.showLinks = this.showLinks.bind(this);
        this.scrollToElement = this.scrollToElement.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0)
      }

    showLinks(obj, i) {
        var dynamicClass = 'not-active sub-nav-li';
        if (obj.title === this.props.currentActive) {
            dynamicClass = 'active sub-nav-li'
        }

        else {
            return (
                <li key={i} role="7" className={`${dynamicClass} ${obj.id === this.state.selectedLink ? 'active sub-nav-li' : ''}`} onClick={() => this.scrollToElement(obj.id)}>
                    <a className="sub-nav-a no-top-margin">{obj.title}</a>
                </li>
            )
        }
    }

    scrollToElement(id) {
        const section = document.querySelector(id);
        if (id === '#mhmApply') {
            section.style.display = 'block';
            section.scrollIntoView({ behavior: 'smooth', block: 'start' })
        } else {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }

        this.setState({
            selectedLink: id
        })
    }

    render() {
        return (
            <div>
                <div className="sub-nav">
                    <div className="container">
                        <div className="">
                            <ul className="nav navbar-nav sub-navbar-nav">
                                {this.state.id.map(this.showLinks)}
                            </ul>
                        </div>
                    </div>
                </div>

                <Slideshow data={this.state.slides} links={false} />
                <div className="makeHerMark-container below-fixed-subnav below-make-her-mark-subnav ">
                    <div className="show-grid">
                    <div id='mhmAbout' className="container about-founder-container make-her-mark-text-container">
                            <div className="col-xs-12">
                                <div className="about-founder-p">
                                    <div className="row about-founder-row">
                                        <div className="col-xs 12 col-sm-6 col-md-8 founder-image-div">
                                            <img className="executive-detail-img about-founder-img" alt="" src='/images/Ashley Williams Headshot.JPG' />
                                        </div>
                                        <div className="col-xs 12 col-sm-6 col-md-4">
                                            <div className="col-xs 12 col-sm-12">
                                            </div>
                                            <div className="col-xs 12 col-sm-12">
                                                <div className='about-us-p about-founder-p'>
                                                    <p><i>"Welcome! Thank you for your interest in Make Her Mark. As a female filmmaker, I know the feeling of being told ‘no’ all too well – that’s why I’m thrilled to partner with Hallmark Media on this program that says ‘yes’ by empowering, training, and supporting women in their directorial pursuits.” – Founder Ashley Williams</i></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
 
                        <div className="container make-her-mark-text-container">
                            <div className="col-xs-12">
                                <div className="make-her-mark-p">
                                    <h4 className='mhm-title mhm-h4'><b>About Hallmark Media’s Make Her Mark Women’s Directing Program</b></h4>

                                    <p>Hallmark Media’s <i>Make Her Mark</i> Women’s Directing Program is an on-the-job mentorship initiative designed to support and increase representation of women behind the camera.</p>

                                    <p>Through an in-depth selection process, Hallmark Media identifies an aspiring female filmmaker who already has some minimal experience behind the camera, whether through film school or directing a short, and pairs her with an established Hallmark director. From there, the candidate shadows her mentor throughout the entire production process of a Hallmark movie, from pre-production to production to post. Participants will work towards the ultimate opportunity of directing a Hallmark movie, supported by their mentor.</p>

                                    <p>The program was conceived and founded by long-time Hallmark Media on- and off-air talent and creative partner, Ashley Williams. Yan-Kay Crystal Lowe was the inaugural participant in the program, shadowing long-time Hallmark director, Jessica Harmon, on the 2023 Hallmark Channel movie <i>Dream Moms</i>. Lowe then made her directorial debut in the 2024 Hallmark Channel film <i>Shifting Gears</i>.</p>

                                    <p>Program participants must have a valid passport for US or Canada and must be non-DGA. To be considered, please submit your resume, reel and/or information on a project you’ve directed, and why you think you’re right for the program via the form below.</p>


                                </div>
                            </div>
                        </div>
                       <div id='mhmFaq' className='container FAQ-container make-her-mark-text-container'>
                            <div className="col-xs-12">
                                <div className="make-her-mark-p">

                                    <h4 className='mhm-faq mhm-h4'><b>FAQ</b></h4>
                                    <p><b>How did <i>Make Her Mark</i> come about, Ashley?</b></p>
                                    <p>As an actress and producer, I noticed that the majority of productions across the industry, including Hallmark movies I was working on, were directed by lovely, experienced, smart... men. Directing a Hallmark movie is a hard job. The movies are shot in a short period of time with a concise budget, and we are tasked with conveying grand messages of love, romance and holiday spirit that resonate with our audience. Finding an experienced director that can be trusted isn’t easy. The answer was simple: Let’s train women, advocate for them, and surround them with a supportive team -- as we employ them.</p>
                                    <br />

                                    <p><b>How does the program work?</b></p>
                                    <p>As an up-and-coming director myself, I didn’t want to just be handed a job. I want to create an opportunity for the first of MANY directing jobs for our candidates. I want them to be hired again and again. Once a candidate is selected, they will “shadow” an experienced Hallmark director for all of prep, shooting, and post on a movie. After evaluating their experience, the executive team will decide if more shadowing is needed. Once complete, we will hire that candidate for their directorial debut and provide them with a creative producer who is also an experienced Hallmark director. She will direct her movie and her creative producer will be there to support, troubleshoot, whisper in her ear as needed, and advocate for her, ensuring her success.</p>
                                    <br />

                                    <p><b>Who is eligible for the program?</b></p>
                                    <p>Female identifying Hallmark movie-loving candidates who have directed at least one short film, have on-set professional experience, and have a clear vision for storytelling.</p>
                                    <br />
                                    <p><b>Do I have to take time off from work to participate in <i>Make Her Mark</i>?</b></p>
                                    <p>YES! The shadow portion of the program is seven weeks, and you will need to be available for all of it with only a few weeks’ notice. Once that’s completed and your post-shadow interview is complete, you may need to shadow more. A few months later, you will be hired to direct a movie and will need seven-plus weeks to perform the duties of that job.</p>
                                    <br />

                                    <p><b>Is there any payment for participating in the program?</b></p>
                                    <p>YES. It was important to us to be able to provide a small stipend to shadows for the duration of their experience. Once hired to direct, directors will be paid a directing fee for their work.</p>
                                    <br />

                                    <p><b>When will I know if I’m in?</b></p>
                                    <p>Our goal is to let people know by July if they’re moving forward to the interview portion of the review. If you don’t hear from us, you have not been accepted into the program. (But that doesn’t mean you shouldn’t apply again! You’ve got this!)</p>

                                </div>
                            </div>
                        </div>
                        <MakeHerMarkForm />
                    </div>
                </div>
                <MakeHerMarkArrow resetSelectedLink={() => this.setState({selectedLink: ''})}/>
            </div>
        );
    }
}

export default MakeHerMark;