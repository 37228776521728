import React, { Component } from 'react';
import './css/styleDocs.css';


class Privacy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: [
                { id: "#financial" },
            ],

        };
        this.scrollToElement = this.scrollToElement.bind(this);
    }



    scrollToElement(id) {
        const section = document.querySelector(id);
        if (id === '#financial') {
            section.style.display = 'block';
            section.scrollIntoView({ behavior: 'smooth', block: 'start' })
        } else {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }

        this.setState({
            selectedLink: id
        })
    }


    render() {

        return (
            <div className='priv-container'>
                <div className="privacy-container">
                    <img className="privacy-logo" src="/images/HallmarkMedia_Logo_Plum_RGB.png" alt="Hallmark Channel Logo" />
                </div>
                <main className="l-static_page-main">
                    <div className="b-user_content">


                        <div className="l-static_page-region"><div className="l-static_page-item">

                            <div
                                className="l-grid_layout m-1_up m-centered m-padding_top-md m-padding_bottom-md  m-margin_top-none m-margin_bottom-none "
                            >
                                <div className="l-grid_layout-content ">

                                    <div className="l-grid_layout-item m-lg_12 m-md_12 m-sm_6 m-hide_animation m-margin_bottom-none "><div>

                                        <div className="b-header_ext m-horizontal_left m-vertical_top">
                                            <div
                                                className="b-header_ext-wrapper "
                                            >

                                                <div className="b-text_block theme-font-color">
                                                    <h1>Hallmark Privacy Policy</h1>

                                                    <p style={{ paddingBottom: "10px", paddingTop: "10px" }}>Last Updated: July 24, 2024</p>

                                                </div>
                                            </div>
                                        </div>
                                    </div><div className="l-grid_layout-item_in">
                                            <div
                                                data-slide-type="lg-4 md-4 sm-4"
                                                className="b-text_block m-horizontal_left m-vertical_top m-padding_top_sm theme-font-color theme-background-color"
                                                style={{ width: '100%' }}
                                            >
                                                <div className="b-text_block-container">
                                                    <p style={{ paddingBottom: '10px' }}>Welcome to Hallmark! As part of our goal to help people create a caring and more connected world, Hallmark Marketing Company, LLC, Hallmark Media United States, LLC, Hallmark Business Connections, LLC, and DaySpring Cards, Inc. (collectively, &ldquo;Hallmark,&rdquo; &ldquo;we,&rdquo; &ldquo;us,&rdquo; &ldquo;our&rdquo;) collect information about you. This Privacy Policy (&ldquo;Privacy Policy&rdquo; or &ldquo;Policy&rdquo;) describes how we collect, use, and disclose your personal information and any rights you may have with respect to such information. It governs personal information we collect through our website(s), microsite(s), mobile website(s), and mobile applications (collectively, &ldquo;Sites&rdquo;) and personal information we collect from you or third parties through any other means to provide our products and services, operate our corporately owned Hallmark Gold Crown stores, or perform other business activities (collectively, &ldquo;Services&rdquo;). The Policy does not apply to personal information we collect in an employment context, such as information collected from or about job applicants or employees, personal information collected in a business-to-business context, or the privacy practices of affiliated entities that maintain their own privacy policies.&nbsp;</p>

                                                    <p style={{ paddingBottom: '10px' }}>Please read this Privacy Policy, which is part of our <a className='a-links' href="https://corporate.hallmarkmedia.com/terms-of-use/">Terms of Use</a>, before using our Sites or Services. By Accessing or using our Sites or Services, you agree to the Terms of Use and this Policy. California residents should also read our <a className='a-links' href="https://corporate.hallmarkmedia.com/privacy-notice/" rel="noreferrer noopener" target="_blank">California Privacy Notice,</a> which supplements this Policy.&nbsp;</p>

                                                </div>
                                            </div>
                                        </div></div>
                                </div>
                            </div>
                        </div><div className="l-static_page-item">
                                <div
                                    className="l-grid_layout m-1_up m-centered m-padding_top-md m-padding_bottom-md  m-margin_top-none m-margin_bottom-md "

                                >
                                    <div className="l-grid_layout-content ">

                                        <div className="l-grid_layout-item m-lg_12 m-md_12 m-sm_6 m-hide_animation m-margin_bottom-none "><div className="l-grid_layout-item_in">

                                            <div className="b-accordion">
                                                <div
                                                    className="b-accordion-inner">
                                                    <div className="b-accordion-items">
                                                        <div data-widget-event-closeallitems="closeItems" data-id="1-changes-0" data-widget="accordionItem" data-widget-event-accordionitemupdate="updateFocusedItem" className="b-accordion-item">

                                                            <h3 className="b-accordion-title">
                                                                <span>About This Privacy Policy</span>
                                                                <span className="b-icon_chevron"></span>
                                                            </h3>
                                                            <div className="b-accordion-content">
                                                                <div className="b-accordion-content_inner" data-ref="accordionItemPanelInner">
                                                                    <div className="b-text_block theme-font-color">
                                                                        <h5>Privacy policy changes</h5>

                                                                        <p style={{ paddingBottom: '10px' }}>We may update this Privacy Policy from time to time. If we make material changes to the Policy, we will update the effective date and provide notice through the Sites or using contact information you&nbsp;provided. Your continued use of the Sites or Services after the effective date of the revised Privacy Policy constitutes your consent to those changes. We may provide you with the ability to opt-in or opt-out of&nbsp;certain changes, as required by law.&nbsp;</p>

                                                                        <h5>Conflict between this policy and laws and regulations&nbsp;</h5>

                                                                        <p style={{ paddingBottom: '10px' }}>When federal, state, or local laws or regulations require a higher level of protection for your personal information, those laws or regulations govern.&nbsp;</p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div></div>
                                                </div>

                                            </div>
                                        </div><div className="l-grid_layout-item_in">

                                            </div><div className="l-grid_layout-item_in">

                                                <div className="b-accordion"
                                                    data-widget="accordion"
                                                    data-allow-toggle="true"
                                                    data-allow-multiple="true"
                                                >
                                                    <div
                                                        className="b-accordion-inner"
                                                    >
                                                        <div className="b-accordion-items"><div data-widget-event-closeallitems="closeItems" data-id="3-information-0" data-widget="accordionItem" data-widget-event-accordionitemupdate="updateFocusedItem" className="b-accordion-item">

                                                            <h3 className="b-accordion-title"
                                                            >
                                                                <span>Information We Collect</span>
                                                                <span className="b-icon_chevron"></span>
                                                            </h3>
                                                            <div
                                                                className="b-accordion-content"
                                                            >
                                                                <div className="b-accordion-content_inner" data-ref="accordionItemPanelInner">
                                                                    <div className="b-text_block theme-font-color">
                                                                        <h5>Personal Information</h5>

                                                                        <p style={{ paddingBottom: '10px' }}>We may collect information that personally identifies, relates to, describes, or is capable of being associated with you (&ldquo;personal information&rdquo;). The types of personal information we collect about you will vary based on how you use the Sites and/or Services and your relationship with us, but may include:&nbsp;</p>

                                                                        <ul>
                                                                            <li><em>Identifiers</em> such as name, shipping address, billing address, email address, phone number, and account information;</li>
                                                                            <li><em>Characteristics of protected classifications under California or federal law</em> such as age, date of birth, and gender;</li>
                                                                            <li><em>Other demographic information</em> such as the number and ages of children, what language(s) you speak, homeowner status, the number of persons living at the property, household income, marital status;</li>
                                                                            <li><em>Commercial information</em> such as bank account number, payment card information, records of Hallmark or third-party products and services purchased or enrolled in, customer type(s), account balance and status, rewards and benefit information, product and service reviews, return and exchange information;</li>
                                                                            <li><em>Internet or other electronic network activity information</em> such as IP address, device specifications, browsing and search history, clicks, information about how you interact with our emails and digital advertisements, and other data collected by cookies and similar technologies;</li>
                                                                            <li><em>Geolocation data</em> such as non-precise geolocation data collected through a mobile app, your IP address, or otherwise;</li>
                                                                            <li><em>Audio, electronic, visual, thermal, olfactory, or similar information</em> such as call recordings, chat transcripts, photos and videos you submit to us, audio files, messages and handwriting, other data provided by you for personalized cards and gifts;</li>
                                                                            <li><em>Professional/employment information</em> such as employer, occupation;</li>
                                                                            <li><em>Education information</em> such as education level; and</li>
                                                                            <li><em>Inferences drawn from other personal information</em> that relate to your preferences, interests, characteristics, psychological trends, predispositions, behavior, attitudes, abilities, and aptitudes. This may include wish lists, watch lists, favorites information, your communications or privacy preferences, and other inferences.</li>
                                                                        </ul>

                                                                        <p style={{ paddingBottom: '10px' }}>Some of the personal information listed above may be considered sensitive personal information under relevant privacy laws. Depending on your state of residence, this may include your financial account information and account login credentials.</p>

                                                                        <h5>Deidentified and aggregate information&nbsp;</h5>

                                                                        <p style={{ paddingBottom: '10px' }}>We may deidentify or aggregate the information described above. Deidentified or aggregated data that we do not attempt to re-identify is not considered personal information as outlined in this Privacy Policy.&nbsp;</p>

                                                                        <h5>Usage data and sites activity&nbsp;</h5>

                                                                        <p style={{ paddingBottom: '10px' }}>We, our service providers, and other third parties automatically collect information in connection with the actions you take on our Sites (&ldquo;Usage Data&rdquo;). For example, each time you use our Sites, we may&nbsp;automatically collect the type of web browser you use, the type of device you use, your operating system and version, your Internet Service Provider, your IP address, your mobile device or other unique&nbsp;identifier, the pages you view, referring and exit pages, the date and time of your visit, the number of clicks to, from, and within our Sites, and the duration of your visits to our Sites. If we can reasonably associate this information with you, we treat it as personal information under the categories of&nbsp;Identifiers or Internet or other electronic network information, as appropriate. If we cannot reasonably associate this information with you, we treat it as Usage Data.&nbsp;</p>

                                                                        <h5>Communication recordings</h5>

                                                                        <p style={{ paddingBottom: '10px' }}>We may record calls and retain the content of text messages, chat transcripts (including live chat through Chat with Care or automated chat through Hallmark&rsquo;s Virtual Helper), emails, and other written or electronic communications between you and us. By communicating with us, you consent to our recording and retention of communications.</p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div></div>
                                                    </div>

                                                </div>
                                            </div><div className="l-grid_layout-item_in">

                                                <div className="b-accordion">

                                                    <div
                                                        className="b-accordion-inner"
                                                    >
                                                        <div className="b-accordion-items"><div data-widget-event-closeallitems="closeItems" data-id="4-how-we-use-0" data-widget="accordionItem" data-widget-event-accordionitemupdate="updateFocusedItem" className="b-accordion-item">

                                                            <h3 className="b-accordion-title">
                                                                <span>How We Collect Information</span>
                                                                <span className="b-icon_chevron"></span>
                                                            </h3>
                                                            <div className="b-accordion-content">
                                                                <div className="b-accordion-content_inner" data-ref="accordionItemPanelInner">
                                                                    <div className="b-text_block theme-font-color">
                                                                        <h5>From you</h5>

                                                                        <p style={{ paddingBottom: '10px' }}>We may ask you to provide us with personal information when you communicate with us online or&nbsp;offline, including as part of enrollment or through surveys, quizzes, forums, and marketing programs. You are not required to provide your personal information; however, if you choose not to provide the requested information, you may not be able to use some features of the Sites or Services, or we may not be able to fulfill your requested interaction. We may also capture personal information when you post it on public websites, including social media sites, or contact us by phone, email, or through other means.&nbsp;</p>

                                                                        <h5>Independent Gold Crown stores</h5>

                                                                        <p style={{ paddingBottom: '10px' }}>Many Hallmark Gold Crown stores are operated by independent retailers who may use their own websites, apps, or other technology to interact with you and collect your personal information.&nbsp;Independent retailers&rsquo; privacy practices are not governed by this Policy; however, they may provide your personal information to Hallmark, including in connection with administering your Crown Rewards&nbsp;account and for marketing purposes. In such case, this Policy applies to Hallmark&rsquo;s direct use of your personal information.&nbsp;</p>

                                                                        <h5>Other third-party data sources&nbsp;</h5>

                                                                        <p style={{ paddingBottom: '10px' }}>We may receive information about you from other third parties, such as business partners (including companies that assist us with online advertising and analytics), data providers who enhance the&nbsp;information we have about you, sources of publicly available information, and other users of our Sites and Services (such as when they provide your information to send you one of our products or participate in a &ldquo;refer a friend&rdquo; or similar program that we may offer).&nbsp;</p>

                                                                        <h5>Cookies and other automated tools&nbsp;</h5>

                                                                        <p style={{ paddingBottom: '10px' }}>We and third parties may use cookies (small text files placed on your device to identify your device and browser), log files, web tags, beacons, pixels, session recordings, embedded scripts, and other automated tools (collectively &ldquo;cookies&rdquo;) to collect personal information and Usage Data, track your interaction with our Sites and Services, improve the experience of the Sites and Services, and conduct cross-context behavioral or targeted advertising (collectively &ldquo;targeted advertising&rdquo;). Many web&nbsp;browsers are initially set up to accept cookies. You can reset your web browser to decline certain types of cookies; however, some features of our Sites may not work if you delete or disable cookies.&nbsp;</p>

                                                                        <p style={{ paddingBottom: '10px' }}>We use the following types of cookies:</p>

                                                                        <ul>
                                                                            <li><em>Strictly Necessary:</em> These cookies are essential to operate the Sites and provide you with services you have requested.</li>
                                                                            <li><em>Functional:</em> These cookies enable certain features or non-essential functionality on our Sites. For example, we might use these cookies to recognize you and remember your preferences or settings when you return to our Sites so we can provide you with a more personalized experience.</li>
                                                                            <li><em>Advertising Cookies:</em> These cookies allow us and third parties to learn more about you, including your use of our Sites and other websites, and advertise products or services that might interest you.</li>
                                                                            <li><em>Performance Cookies:</em> These cookies help us and third parties understand how our Sites work and who visits them. For example, these cookies allow us to measure and improve the performance of our Sites, determine which pages are the most and least popular, and see how visitors move around the Sites.</li>
                                                                        </ul>

                                                                        <p style={{ paddingBottom: '10px' }}>You can opt-out of certain types of cookies by (1) clicking the &ldquo;Cookie Preferences&rdquo; link in the footer on our Sites or (2) using your browser or a browser extension to send our Sites a <a className='a-links' href="https://globalprivacycontrol.org/" rel="noreferrer noopener" target="_blank">Global Privacy Control</a> or <a className='a-links' href="https://fpf.org/thank-you-for-visiting-allaboutdnt-com/" rel="noreferrer noopener" target="_blank">Do Not Track</a> signal.&nbsp;</p>

                                                                        <p style={{ paddingBottom: '10px' }}>If you arrive at our Sites by &ldquo;clicking through&rdquo; from another website, we and third parties may receive additional information from that website such as the terms you searched that led you to our Sites. You should review the Privacy Policy of any website from which you reached our Sites to determine what information the operator collects and how it uses such information. We may retain information about you provided to us by other websites and will use it in accordance with this Privacy Policy. We may associate such information with other Usage Data or personal information.&nbsp;</p>

                                                                        <h5>Targeted advertising&nbsp;</h5>

                                                                        <p style={{ paddingBottom: '10px' }}>We may work with third-party advertisers, search providers, and ad networks (&ldquo;Advertisers&rdquo;) to learn more about you and show you ads or other content that we believe would be relevant to you.&nbsp;Advertisers may collect and use information about your use of our Sites or Services as well as other&nbsp;websites and services. These companies may use cookies to collect and use your information. We and/or Advertisers may also append other data to the data collected by Advertisers to create an interest profile of individual users. Our Privacy Policy does not cover use by an Advertiser of information that they&nbsp;collect from you.&nbsp;</p>

                                                                        <p style={{ paddingBottom: '10px' }}>Some Advertisers that collect information for targeted advertising are members of the Digital Advertising Alliance (DAA) or the Network Advertising Initiative (NAI), both of which maintain websites where&nbsp;people can opt out of targeted advertising from their members. If you want to reduce the targeted&nbsp;advertising you receive, you can visit the <a className='a-links' href="http://www.aboutads.info/choices/" rel="noreferrer noopener" target="_blank">DAA Consumer Choice Page</a> and/or the <a className='a-links' href="https://optout.networkadvertising.org/?c=1" rel="noreferrer noopener" target="_blank">NAI Consumer Opt-Out</a> <a className='a-links' href="https://optout.networkadvertising.org/?c=1" rel="noreferrer noopener" target="_blank">Page.</a> These opt-outs may be device and browser specific.&nbsp;</p>

                                                                        <h5>Information from advertisements&nbsp;</h5>

                                                                        <p style={{ paddingBottom: '10px' }}>If you arrive at our Sites via an advertisement (e.g., banner ad), we may collect information regarding the advertisement with which you interacted and your interactions (e.g., item clicked, date and time).&nbsp;</p>

                                                                        <h5>Social media features&nbsp;</h5>

                                                                        <p style={{ paddingBottom: '10px' }}>We may host or facilitate interactions with social media platforms, like Facebook, YouTube, Pinterest, TikTok, and Instagram. You may also have the opportunity to log in through or otherwise connect your&nbsp;social media account. When you interact with social media through our Sites, we will receive information about you from the social media service and you choose to share information about your interactions&nbsp;with Hallmark with that social media service.&nbsp;</p>

                                                                        <p style={{ paddingBottom: '10px' }}>Subject to any cookie preferences you may select, social media services may set their own cookies and collect information about your use of our Sites, including your IP address, other device identifiers, and which pages you visit on the Sites. The information we collect is subject to this Privacy Policy. The information third parties collect is subject to their privacy policies.&nbsp;</p>

                                                                        <h5>ARKit&nbsp;</h5>

                                                                        <p style={{ paddingBottom: '10px' }}>Our mobile applications may use Apple's ARKit programming library to offer certain camera-based features, including allowing users to add image and video content to photos taken and videos recorded via an Apple mobile device's front-facing camera. The ARKit library includes Apple's TrueDepth API, which automatically records data related to your mobile device's camera positioning while using these camera-based features. Data gathered by the TrueDepth API does not leave your device.&nbsp;</p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div></div>
                                                    </div>

                                                </div>
                                            </div><div className="l-grid_layout-item_in">

                                                <div className="b-accordion">




                                                    <div
                                                        className="b-accordion-inner"
                                                    >
                                                        <div className="b-accordion-items"><div data-widget-event-closeallitems="closeItems" data-id="5-how-we-share-info-0" data-widget="accordionItem" data-widget-event-accordionitemupdate="updateFocusedItem" className="b-accordion-item">
                                                            <h3 className="b-accordion-title"
                                                            >
                                                                <span>How We Use and Disclose Information</span>
                                                                <span className="b-icon_chevron"></span>
                                                            </h3>
                                                            <div
                                                                className="b-accordion-content"
                                                                id="5-how-we-share-info-0"
                                                                data-ref="accordionItemPanel"
                                                                aria-labelledby="5-how-we-share-info-0-btn"
                                                            >
                                                                <div className="b-accordion-content_inner" data-ref="accordionItemPanelInner">
                                                                    <div className="b-text_block theme-font-color">
                                                                        <h5>General uses of personal information</h5>

                                                                        <p style={{ paddingBottom: '10px' }}>We may use personal information and sensitive personal information for business purposes, such as:&nbsp;</p>

                                                                        <ul>
                                                                            <li><em>Providing the Sites and Services:</em> to create customer accounts, allow customers to create online profiles, process payments, respond to requests for information, keep customers informed about the status of their Services, operate rewards programs, and for general account servicing and customer support purposes;</li>
                                                                            <li><em>Marketing, market research, and analytics:</em> to market our and third parties&rsquo; products and services, perform analytics, and conduct market research;</li>
                                                                            <li><em>Other company communications:</em> to otherwise communicate with you about our Sites or Services;</li>
                                                                            <li><em>Website analytics:</em> to analyze consumers&rsquo; use of our Sites, including the use of cookies and third- party analytics services;</li>
                                                                            <li><em>Product research and development:</em> to conduct research and analysis for maintaining, protecting, developing, and improving our Sites and Services;</li>
                                                                            <li><em>Business operations:</em> to evaluate, develop, and improve business operations, conduct audits, resolve technical issues, and perform business administration and other normal business activities;</li>
                                                                            <li><em>Compliance:</em> to comply with legal requirements, industry standards, contractual obligations, our policies, and take actions that we deem necessary to preserve and enforce our rights and the rights of others;</li>
                                                                            <li><em>Information security and fraud prevention:</em> to verify your identity and operate information security and anti-fraud programs; and</li>
                                                                            <li><em>Other purposes:</em> as otherwise described to you at the point of collection, with your consent, or as otherwise required by law.</li>
                                                                        </ul>

                                                                        <h5>Personalized product services</h5>

                                                                        <p style={{ paddingBottom: '10px' }}>You may be able to personalize the products you purchase from us or that we make available to you. For instance, you may be able to add your own photo, video, handwritten note or other content (&ldquo;Consumer Content&rdquo;). You are fully responsible for your Consumer Content, which must comply with all laws, the Terms of Use, and any other applicable terms provided. We have the right, but not the obligation, to monitor Consumer Content and to refuse to include any Consumer Content that we believe violates law or our policies. Hallmark may permit you to store your Consumer Content as drafts or for re-use. By providing Consumer Content, you agree that Hallmark may analyze such content to assist us in enhancing our products and services, getting to know you better, and to market Services we think you may be interested in.</p>

                                                                        <h5>Marketing and communication opt-outs</h5>

                                                                        <p style={{ paddingBottom: '10px' }}>We may provide you with the option to update your information or to opt out of marketing communications through the profile and/or preferences section of your account. In addition, you may opt out of marketing communications as set forth below.</p>

                                                                        <ul>
                                                                            <li><em>Email.</em> You may unsubscribe from some or all marketing emails through the opt-out mechanism included in each email. Certain Hallmark entities may operate independent email campaigns with separate opt-out mechanisms. You may still receive nonmarketing emails from us, including communications about your account.</li>
                                                                            <li><em>Text Messages.</em> If you signed up to receive SMS, MMS, or RCS messages from Hallmark or one of our brands, you may unsubscribe by replying &ldquo;STOP&rdquo; to number from which you received such messages. If you signed up for multiple programs, you may need to opt out of each program separately.</li>
                                                                            <li><em>Push Notifications.</em> We may send promotional and non-promotional push notifications or alerts to your mobile device, and may in some cases do so based on your device&rsquo;s non-precise location. You can elect to stop receiving those messages at any time by changing the notification settings on your mobile device.</li>
                                                                        </ul>

                                                                        <h5>Service providers</h5>

                                                                        <p style={{ paddingBottom: '10px' }}>We may disclose your personal information to other businesses to provide services to us or to assist us in providing our Sites and Services. Examples include fulfillment and account servicing vendors, payment processors, customer service providers, marketing and communications vendors, delivery services, data analytics vendors, research and development vendors, IT and network administration vendors, streaming content platforms, professional service firms, other administrative services providers.</p>

                                                                        <h5>Third-party selling and sharing</h5>

                                                                        <p style={{ paddingBottom: '10px' }}>We may disclose personal information to third parties in situations that qualify as a &ldquo;sale&rdquo; or &ldquo;sharing&rdquo; (for targeted advertising purposes) under applicable privacy laws. Categories of third parties to whom we may sell or share personal information include affiliates, independent Hallmark retailers, sports leagues and organizations, other retailers or sellers of consumer goods or services, and advertising, social media, and analytics companies. These third parties may use your personal information for their everyday business purposes, including marketing. Although we may sell or share Identifiers generally, we do not sell or share text message &ldquo;opt-in&rdquo; data.</p>

                                                                        <h5>Other third-party disclosures</h5>

                                                                        <p style={{ paddingBottom: '10px' }}>We may occasionally disclose your personal information to third parties for purposes that do not constitute a sale or sharing (for targeted advertising purposes). For example:</p>

                                                                        <ul>
                                                                            <li>To comply with the law;</li>
                                                                            <li>To respond to legal requests (including court orders, investigative demands and subpoenas) if, in our discretion, we believe it is necessary or appropriate;</li>
                                                                            <li>To prevent or stop illegal, unethical, or legally actionable activity;</li>
                                                                            <li>To protect the safety, property, or rights of ourselves, consumers, or any other third party;</li>
                                                                            <li>In connection with a proposed or actual merger, acquisition, financing due diligence, reorganization, bankruptcy, receivership, sale of company assets (whether by private sale, through operation of law, as part of a divestiture plan, or otherwise), or similar corporate transaction;</li>
                                                                            <li>To businesses controlling, controlled by, or under common control with us;</li>
                                                                            <li>To independent retailers or other third parties to fulfill your orders or process transactions; and</li>
                                                                            <li>For additional purposes with your consent or at your direction.</li>
                                                                        </ul>

                                                                        <h5>Consent for data transfers</h5>

                                                                        <p style={{ paddingBottom: '10px' }}>Hallmark is based in the United States. Please be aware that your connection will be through and to servers located in the United States and other countries. By using our Sites and/or Services, or otherwise providing us with any information, you agree that we may process and store your personal information in the United States and in other jurisdictions, for which the privacy laws may not be as comprehensive as those in the country where you reside and/or are a citizen. As a result, this information may be subject to access requests from governments, courts, or law enforcement in the United States and other countries according to the laws in those jurisdictions.</p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div></div>
                                                    </div>
                                                </div>
                                            </div><div className="l-grid_layout-item_in">
                                                <div className="b-accordion">

                                                    <div
                                                        className="b-accordion-inner"
                                                    >
                                                        <div className="b-accordion-items"><div data-widget-event-closeallitems="closeItems" data-id="5-2-privacy-chart-0" data-widget="accordionItem" data-widget-event-accordionitemupdate="updateFocusedItem" className="b-accordion-item">

                                                            <h3 className="b-accordion-title"
                                                                style={{}}
                                                            >
                                                                <span>Summary of Privacy Practices</span>
                                                                <span className="b-icon_chevron"></span>
                                                            </h3>
                                                            <div
                                                                className="b-accordion-content"
                                                                id="5-2-privacy-chart-0"
                                                                data-ref="accordionItemPanel"
                                                                aria-labelledby="5-2-privacy-chart-0-btn"
                                                            >
                                                                <div className="b-accordion-content_inner" data-ref="accordionItemPanelInner">
                                                                    <div className="b-text_block theme-font-color">
                                                                        <h5>Summary of privacy practices over the past 12 months</h5>

                                                                        <p style={{ paddingBottom: '10px' }}>This chart summarizes our privacy practices in the 12 months preceding the Last Updated date of this Policy, by category of personal information, as contemplated under certain privacy laws. Please note that we may not use or disclose each data element within a given category of personal information for the same purposes.</p>

                                                                        <div style={{ overflowY: "auto" }}>
                                                                            <table border="1" cellPadding="1" cellSpacing="1">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th className="text_color_light" scope="col"><span className="text_color_light">Categories of Personal Information</span></th>
                                                                                        <th scope="col">Purposes of Processing</th>
                                                                                        <th scope="col">Disclosures</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td data-label="Categories of Personal Information" style={{ textAlign: "center", verticalAlign: "middle" }}>All categories&nbsp;</td>
                                                                                        <td data-label="Purposes of Processing">
                                                                                            <ul>
                                                                                                <li>Business operations</li>
                                                                                                <li>Compliance</li>
                                                                                                <li>As described to you at the point of collection of with your consent</li>
                                                                                            </ul>
                                                                                        </td>
                                                                                        <td data-label="Disclosures">
                                                                                            <p className="text_color_light" style={{ textAlign: "center" }}><em className="text_color_light">Service providers.</em> We disclosed each category of personal information to service providers.&nbsp;</p>

                                                                                            <p style={{ textAlign: "center" }}><em>Third parties.</em> We may have disclosed each category of personal information for the purposes listed in the &ldquo;Other third-party disclosures&rdquo; subsection.&nbsp;</p>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="text_color_light" data-label="Categories of Personal Information" style={{ textAlign: "center", verticalAlign: "middle" }}>Identifiers</td>
                                                                                        <td data-label="Purposes of Processing">
                                                                                            <ul>
                                                                                                <li>Provide the Sites and Services&nbsp;</li>
                                                                                                <li>Marketing, market research, analytics&nbsp;</li>
                                                                                                <li>Other company communications&nbsp;</li>
                                                                                                <li>Website analytics&nbsp;</li>
                                                                                                <li>Product research and development&nbsp;</li>
                                                                                                <li>Information security and fraud prevention&nbsp;</li>
                                                                                            </ul>
                                                                                        </td>
                                                                                        <td data-label="Disclosures">
                                                                                            <p style={{ textAlign: "center" }}><em>Affiliates.</em> We disclosed this information to affiliates for marketing and general business purposes. This may qualify as sale under certain privacy laws.&nbsp;</p>

                                                                                            <p style={{ textAlign: "center" }}><em>Advertising, social media, and analytics companies. </em>We disclosed this information to these companies for marketing and analytics purposes. This qualifies as a sale (or sharing of PI for targeted advertising purposes) under certain privacy laws.&nbsp;</p>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td data-label="Categories of Personal Information" style={{ textAlign: "center", verticalAlign: "middle" }}>Characteristics of protected classifications under California or federal law&nbsp;</td>
                                                                                        <td data-label="Purposes of Processing">
                                                                                            <ul>
                                                                                                <li>Provide the Sites and Services&nbsp;</li>
                                                                                                <li>Marketing, market research, analytics&nbsp;</li>
                                                                                                <li>Other company communications&nbsp;</li>
                                                                                                <li>Product research and development&nbsp;</li>
                                                                                                <li>Information security and fraud prevention&nbsp;</li>
                                                                                            </ul>
                                                                                        </td>
                                                                                        <td data-label="Disclosures" style={{ textAlign: "center" }}>We did not sell this information or use it for targeted advertising.&nbsp;</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="text_color_light" data-label="Categories of Personal Information" style={{ textAlign: "center" }}>Other demographic information&nbsp;</td>
                                                                                        <td data-label="Purposes of Processing">
                                                                                            <ul>
                                                                                                <li>Provide the Sites and Services&nbsp;</li>
                                                                                                <li>Marketing, market research, analytics&nbsp;</li>
                                                                                                <li>Other company communications&nbsp;</li>
                                                                                                <li>Product research and development&nbsp;</li>
                                                                                            </ul>
                                                                                        </td>
                                                                                        <td data-label="Disclosures">
                                                                                            <p style={{ textAlign: "center" }}><em>Affiliates.</em> We disclosed this information to affiliates for marketing and general business purposes. This may qualify as sale under certain privacy laws.&nbsp;</p>

                                                                                            <p style={{ textAlign: "center" }}><em>Advertising, social media, and analytics companies.</em> We disclosed this information for marketing and analytics purposes. This qualifies as a sale (or sharing of PI for targeted advertising purposes) under certain privacy laws.&nbsp;</p>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td data-label="Categories of Personal Information" style={{ textAlign: "center" }}>Commercial information&nbsp;</td>
                                                                                        <td data-label="Purposes of Processing">
                                                                                            <ul>
                                                                                                <li>Provide the Sites and Services&nbsp;</li>
                                                                                                <li>Marketing, market research, analytics&nbsp;</li>
                                                                                                <li>Other company communications&nbsp;</li>
                                                                                                <li>Website analytics&nbsp;</li>
                                                                                                <li>Product research and development&nbsp;</li>
                                                                                                <li>Information security and fraud prevention&nbsp;</li>
                                                                                            </ul>
                                                                                        </td>
                                                                                        <td data-label="Disclosures" style={{ textAlign: "center" }}><em>Affiliates.</em> We disclosed this information to affiliates for marketing and general business purposes. This may qualify as sale under certain privacy laws.&nbsp;</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="text_color_light" data-label="Categories of Personal Information" style={{ textAlign: "center" }}>Internet or other electronic network activity information&nbsp;</td>
                                                                                        <td data-label="Purposes of Processing">
                                                                                            <ul>
                                                                                                <li className="text_color_light">Provide the Sites and Services&nbsp;</li>
                                                                                                <li>Marketing, market research, analytics&nbsp;</li>
                                                                                                <li>Other company communications&nbsp;</li>
                                                                                                <li>Website analytics&nbsp;</li>
                                                                                                <li>Product research and development&nbsp;</li>
                                                                                                <li>Information security and fraud prevention&nbsp;</li>
                                                                                            </ul>
                                                                                        </td>
                                                                                        <td data-label="Disclosures" style={{ textAlign: "center" }}>Advertising, social media, and analytics companies. We disclosed this information for marketing and analytics purposes. This qualifies as a sale (or sharing of PI for targeted advertising purposes) under certain privacy laws.&nbsp;</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td data-label="Categories of Personal Information" style={{ textAlign: "center" }}>Geolocation data (non-precise)&nbsp;</td>
                                                                                        <td data-label="Purposes of Processing">
                                                                                            <ul>
                                                                                                <li>Provide the Sites and Services&nbsp;</li>
                                                                                                <li>Marketing, market research, analytics&nbsp;</li>
                                                                                                <li>Other company communications&nbsp;</li>
                                                                                                <li>Website analytics&nbsp;</li>
                                                                                                <li>Product research and development&nbsp;</li>
                                                                                                <li>Information security and fraud prevention&nbsp;</li>
                                                                                            </ul>
                                                                                        </td>
                                                                                        <td data-label="Disclosures" style={{ textAlign: "center" }}>We did not sell this information or use it for targeted advertising.&nbsp;</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="text_color_light" data-label="Categories of Personal Information" style={{ textAlign: "center" }}>Audio, electronic, visual, thermal, olfactory, or similar information&nbsp;</td>
                                                                                        <td data-label="Purposes of Processing">
                                                                                            <ul>
                                                                                                <li className="text_color_light">Provide the Sites and Services&nbsp;</li>
                                                                                                <li>Marketing, market research, analytics&nbsp;</li>
                                                                                                <li>Other company communications&nbsp;</li>
                                                                                                <li>Website analytics&nbsp;</li>
                                                                                                <li>Product research and development&nbsp;</li>
                                                                                                <li>Information security and fraud prevention&nbsp;</li>
                                                                                            </ul>
                                                                                        </td>
                                                                                        <td data-label="Disclosures" style={{ textAlign: "center" }}>We did not sell this information or use it for targeted advertising.&nbsp;</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td data-label="Categories of Personal Information" style={{ textAlign: "center" }}>Professional/employment information&nbsp;</td>
                                                                                        <td data-label="Purposes of Processing">
                                                                                            <ul>
                                                                                                <li>Provide the Sites and Services&nbsp;</li>
                                                                                                <li>Marketing, market research, analytics&nbsp;</li>
                                                                                                <li>Other company communications&nbsp;</li>
                                                                                                <li>Website analytics&nbsp;</li>
                                                                                                <li>Product research and development&nbsp;</li>
                                                                                                <li>Information security and fraud prevention&nbsp;</li>
                                                                                            </ul>
                                                                                        </td>
                                                                                        <td data-label="Disclosures" style={{ textAlign: "center" }}>We did not sell this information or use it for targeted advertising.&nbsp;</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td data-label="Categories of Personal Information" style={{ textAlign: "center" }}>Education information&nbsp;</td>
                                                                                        <td data-label="Purposes of Processing">
                                                                                            <ul>
                                                                                                <li>Provide the Sites and Services&nbsp;</li>
                                                                                                <li>Marketing, market research, analytics&nbsp;</li>
                                                                                                <li>Other company communications&nbsp;</li>
                                                                                                <li>Website analytics&nbsp;</li>
                                                                                                <li>Product research and development&nbsp;</li>
                                                                                                <li>Information security and fraud prevention&nbsp;</li>
                                                                                            </ul>
                                                                                        </td>
                                                                                        <td data-label="Disclosures" style={{ textAlign: "center" }}>We did not sell this information or use it for targeted advertising.&nbsp;</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td data-label="Categories of Personal Information" style={{ textAlign: "center" }}>Inferences drawn from other personal information&nbsp;</td>
                                                                                        <td data-label="Purposes of Processing">
                                                                                            <ul>
                                                                                                <li>Provide the Sites and Services&nbsp;</li>
                                                                                                <li>Marketing, market research, analytics&nbsp;</li>
                                                                                                <li>Other company communications&nbsp;</li>
                                                                                                <li>Website analytics&nbsp;</li>
                                                                                                <li>Product research and development&nbsp;</li>
                                                                                            </ul>
                                                                                        </td>
                                                                                        <td data-label="Disclosures" style={{ textAlign: "center" }}>We did not sell this information or use it for targeted advertising; however, third parties with cookies on our Sites might use information collected from our Sites to draw their own inferences about you.&nbsp;</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td data-label="Categories of Personal Information" style={{ textAlign: "center" }}><em className="text_color_light">Sensitive personal information:</em> account login credentials&nbsp;</td>
                                                                                        <td data-label="Purposes of Processing">
                                                                                            <ul>
                                                                                                <li>Provide the Sites and Services&nbsp;</li>
                                                                                                <li>Website analytics&nbsp;</li>
                                                                                                <li>Information security and fraud prevention&nbsp;</li>
                                                                                            </ul>
                                                                                        </td>
                                                                                        <td data-label="Disclosures" style={{ textAlign: "center" }}>We did not sell this information or use it for targeted advertising.&nbsp;</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td data-label="Categories of Personal Information" style={{ textAlign: "center" }}><em>Sensitive personal information:</em> financial account information&nbsp;</td>
                                                                                        <td data-label="Purposes of Processing">
                                                                                            <ul>
                                                                                                <li className="text_color_light">Provide the Sites and Services&nbsp;</li>
                                                                                                <li>Information security and fraud prevention&nbsp;</li>
                                                                                            </ul>
                                                                                        </td>
                                                                                        <td data-label="Disclosures" style={{ textAlign: "center" }}>We did not sell this information or use it for targeted advertising.&nbsp;</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div></div>
                                                    </div>

                                                </div>
                                            </div><div className="l-grid_layout-item_in"><style>

                                            </style>

                                                <div className="b-accordion">




                                                    <div
                                                        className="b-accordion-inner"
                                                    >
                                                        <div className="b-accordion-items"><div data-widget-event-closeallitems="closeItems" data-id="6-third-party-ads-0" data-widget="accordionItem" data-widget-event-accordionitemupdate="updateFocusedItem" className="b-accordion-item"><style>

                                                        </style>

                                                            <h3 className="b-accordion-title"
                                                                style={{}}
                                                            >
                                                                <span>Third-Party Websites and Links</span>
                                                                <span className="b-icon_chevron"></span>
                                                            </h3>
                                                            <div
                                                                className="b-accordion-content"
                                                                id="6-third-party-ads-0"
                                                                data-ref="accordionItemPanel"

                                                                aria-labelledby="6-third-party-ads-0-btn"
                                                            >
                                                                <div className="b-accordion-content_inner" data-ref="accordionItemPanelInner">
                                                                    <div className="b-text_block theme-font-color">
                                                                        <p style={{ paddingBottom: '10px' }}>The Sites or Services may contain links to third party websites including social media platforms. We are not responsible for those sites&rsquo; content, links, or privacy procedures.&nbsp;</p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div></div>
                                                    </div>

                                                </div>
                                            </div><div className="l-grid_layout-item_in">

                                                <div className="b-accordion">




                                                    <div
                                                        className="b-accordion-inner"
                                                    >
                                                        <div className="b-accordion-items"><div data-widget-event-closeallitems="closeItems" data-id="7-do-not-track-0" data-widget="accordionItem" data-widget-event-accordionitemupdate="updateFocusedItem" className="b-accordion-item"><style>

                                                        </style>

                                                            <h3 className="b-accordion-title"
                                                                style={{}}
                                                            >
                                                                <span>Data Security</span>
                                                                <span className="b-icon_chevron"></span>
                                                            </h3>
                                                            <div
                                                                className="b-accordion-content"
                                                                id="7-do-not-track-0"
                                                                data-ref="accordionItemPanel"

                                                                aria-labelledby="7-do-not-track-0-btn"
                                                            >
                                                                <div className="b-accordion-content_inner" data-ref="accordionItemPanelInner">
                                                                    <div className="b-text_block theme-font-color">
                                                                        <p style={{ paddingBottom: '10px' }}>We recognize the importance of safeguarding the confidentiality of personal information from loss, misuse, or alteration. We employ commercially reasonable administrative, technical, and physical&nbsp;safeguards to protect personal information from unauthorized access, disclosure, and use. Even with these safeguards, no data transmission over the Internet or other network can be guaranteed 100% secure. As a result, while we strive to protect information transmitted on or through our Sites or Services, you do so at your own risk.&nbsp;</p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div></div>
                                                    </div>

                                                </div>
                                            </div><div className="l-grid_layout-item_in">

                                                <div className="b-accordion">

                                                    <div
                                                        className="b-accordion-inner"
                                                    >
                                                        <div className="b-accordion-items"><div data-widget-event-closeallitems="closeItems" data-id="8-your-choices-0" data-widget="accordionItem" data-widget-event-accordionitemupdate="updateFocusedItem" className="b-accordion-item">

                                                            <h3 className="b-accordion-title"
                                                                style={{}}
                                                            >
                                                                <span>Children's Privacy</span>
                                                                <span className="b-icon_chevron"></span>
                                                            </h3>
                                                            <div
                                                                className="b-accordion-content"
                                                                id="8-your-choices-0"
                                                                data-ref="accordionItemPanel"

                                                                aria-labelledby="8-your-choices-0-btn"
                                                            >
                                                                <div className="b-accordion-content_inner" data-ref="accordionItemPanelInner">
                                                                    <div className="b-text_block theme-font-color">
                                                                        <p style={{ paddingBottom: '10px' }}>Our Sites and Services are not intended for use by individuals younger than 18. We do not knowingly collect or share personal information from individuals younger than the age of 18.&nbsp;</p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div></div>
                                                    </div>

                                                </div>
                                            </div > <div className="l-grid_layout-item_in">

                                                <div className="b-accordion">

                                                    <div
                                                        className="b-accordion-inner"
                                                    >
                                                        <div className="b-accordion-items">
                                                            <div id='financial' data-widget-event-closeallitems="closeItems" data-id="9-security-0" data-widget="accordionItem" data-widget-event-accordionitemupdate="updateFocusedItem" className="b-accordion-item"><style>

                                                            </style>

                                                                <h3 className="b-accordion-title"
                                                                    style={{}}
                                                                >
                                                                    <span>Notice of Financial Incentives</span>
                                                                    <span className="b-icon_chevron"></span>
                                                                </h3>
                                                                <div
                                                                    className="b-accordion-content"
                                                                    id="9-security-0"
                                                                    data-ref="accordionItemPanel"

                                                                    aria-labelledby="9-security-0-btn"
                                                                >
                                                                    <div className="b-accordion-content_inner" data-ref="accordionItemPanelInner">
                                                                        <div className="b-text_block theme-font-color">
                                                                            <p style={{ paddingBottom: '10px' }}>Hallmark provides special offers and benefits to customers who participate in our Crown Rewards&nbsp;Program (&ldquo;Program&rdquo;). The Program qualifies as a permissible financial incentive under applicable privacy laws. Our Sites include additional details about current program benefits and <a className='a-links' href="https://corporate.hallmarkmedia.com/crown-rewards-terms-conditions/" rel="noreferrer noopener" target="_blank">applicable terms.</a>&nbsp;</p>

                                                                            <p style={{ paddingBottom: '10px' }}>You may register for the Program through our online sign-up form or certain mobile applications. You may also register by calling us at 1-800-HALLMARK or by completing an application or supplying contact information to an associate in a U.S. Hallmark Gold Crown store or other participating Program location. You may opt out of the Program by calling 1-800-HALLMARK to cancel your membership.&nbsp;</p>

                                                                            <p style={{ paddingBottom: '10px' }}>We may collect, use, and disclose each category of personal information as described above. We do not sell personal information in connection with the Program beyond the general scenarios described above. We may use Identifiers, Internet or other network activity information, and Commercial information for targeted advertising purposes in connection with the Program.&nbsp;</p>

                                                                            <p style={{ paddingBottom: '10px' }}>If you are a resident of a state that provides privacy rights and you request that we delete your personal information, we will no longer be able to provide the Program to you because we cannot (1)&nbsp;communicate with you about the Program once we delete your contact information, (2) accurately track your purchases or activities to allocate rewards, and/or (3) determine your eligibility for certain rewards.&nbsp;</p>

                                                                            <p style={{ paddingBottom: '10px' }}>Hallmark benefits financially from the Program through increased customer loyalty and sales but we do not assign a monetary value to the personal information we collect from members. The precise value members receive could vary greatly by individual, depending on the number of dollars spent and points accumulated, rewards redeemed, other Hallmark programs the member takes advantage of, whether someone is an occasional or regular customer of Hallmark's, and many other factors. Some benefits to members may be intangible, such as the ability to streamline ordering or payment. The aggregate customer benefits are reasonably related to the value Hallmark receives from customers&rsquo; personal information after factoring in expenses associated with running the Program.&nbsp;</p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div></div>
                                                    </div>

                                                </div>
                                            </div > <div className="l-grid_layout-item_in">
                                                <div className="b-accordion">

                                                    <div
                                                        className="b-accordion-inner"
                                                    >
                                                        <div className="b-accordion-items"><div data-widget-event-closeallitems="closeItems" data-id="10-third-party-content-0" data-widget="accordionItem" data-widget-event-accordionitemupdate="updateFocusedItem" className="b-accordion-item"><style>

                                                        </style>

                                                            <h3 className="b-accordion-title"
                                                                style={{}}
                                                            >
                                                                <span>Additional Privacy Rights</span>
                                                                <span className="b-icon_chevron"></span>
                                                            </h3>
                                                            <div
                                                                className="b-accordion-content"
                                                                id="10-third-party-content-0"
                                                                data-ref="accordionItemPanel"

                                                                aria-labelledby="10-third-party-content-0-btn"
                                                            >
                                                                <div className="b-accordion-content_inner" data-ref="accordionItemPanelInner">
                                                                    <div className="b-text_block theme-font-color">
                                                                        <h5>Canadian residents&nbsp;</h5>

                                                                        <p style={{ paddingBottom: '10px' }}>We make reasonable efforts to notify you of the purposes for which personal information will be used or disclosed and if the initial purpose changes. You may withdraw consent at any time (subject to legal or contractual restrictions and reasonable notice). Subject to certain limits set out in the applicable laws,&nbsp;Canadian residents also have the right to request access to the personal information that we collect and to update or correct personal information if it is inaccurate.&nbsp;</p>

                                                                        <p style={{ paddingBottom: '10px' }}>If you are a Canadian resident and would like to submit a request to access your personal information or to withdraw consent to the processing of your personal information, you must submit your request by&nbsp;using the Email Us or Chat links on our Consumer Care site, or calling us at 1-800-HALLMARK. You may also use this contact to submit any requests for information on our privacy practices or to submit a complaint regarding our practices.&nbsp;</p>

                                                                        <p style={{ paddingBottom: '10px' }}>Your request or complaint must include your full name, street address, city, province, postal code, and an email address so we can locate your record and contact you about your request, as needed.&nbsp;</p>

                                                                        <p style={{ paddingBottom: '10px' }}>We cannot properly process requests or complaints that do not come through the designated request mechanism or do not contain sufficient information to allow us to process your request. You may also be required to take reasonable steps as we determine from time to time in order to verify your identity and/or the authenticity of the request. Once your request is processed, absent exemptions, we will&nbsp;provide you with details regarding what personal information we have, how it is used, and with which third parties it is shared.&nbsp;</p>

                                                                        <p style={{ paddingBottom: '10px' }}>Canadian residents may have additional rights under Canadian law, including the Personal Information Protection and Electronic Documents Act (PIPEDA) and successor legislation. Please see the website of the <a className='a-links' href="https://www.priv.gc.ca/" rel="noreferrer noopener" target="_blank">Office of the Privacy Commissioner of Canada</a> for further information.&nbsp;</p>

                                                                        <p style={{ paddingBottom: '10px' }}>Canadian residents also may learn more about targeted advertisements from participating third parties in Canada at the Canadian DAA choice page at <a className='a-links' href="https://youradchoices.ca/" rel="noreferrer noopener" target="_blank">https://youradchoices.ca/.</a>&nbsp;</p>

                                                                        <p style={{ paddingBottom: '10px' }}>You and we confirm that it is our wish that this Privacy Policy, the Terms of Use, and all other related policies be drawn up in English. Vous reconnaissez avoir exig&eacute; la r&eacute;daction en anglais du pr&eacute;sent document ainsi que tous les documents qui s'y rattachent.&nbsp;</p>

                                                                        <h5>Residents of select U.S. states&nbsp;</h5>

                                                                        <p style={{ paddingBottom: '10px' }}>Depending on where you live (including California, Colorado, Connecticut, Delaware, Iowa, Montana,&nbsp;Nebraska, Nevada, New Hampshire, New Jersey, Oregon, Texas, Utah, or Virginia), and subject to&nbsp;applicable exemptions, you may have additional rights to access and control your personal information. Rights vary by state, but may include:&nbsp;</p>

                                                                        <ol>
                                                                            <li><em>Right to opt-out from the sale or sharing of personal information.</em> You may have the right to direct us not to sell or share (for targeted advertising purposes) your personal information to third parties.</li>
                                                                            <li><em>Right to know.</em> You may have the right to know what personal information we have collected about you, including the categories or specific pieces of personal information we collect about you, the categories of sources from which it is collected, the business or commercial purpose(s) for collecting it, and the categories of third parties to whom we sold or disclosed it. Residents of certain states may have the right to receive the names of third parties to whom we disclosed personal information.</li>
                                                                            <li><em>Right to delete.</em> You may have the right to request that we delete information we collected about you.</li>
                                                                            <li><em>Right to correct.</em> You may have the right to request that we correct inaccurate personal information we maintain about you. If you only wish to update your contact information or other personal information rather than disputing its accuracy, please make the changes directly in your account or contact us at 1-877-203-2262.</li>
                                                                            <li><em>Right to appeal.</em> You may have the right to appeal our decision with respect to a request you have submitted.</li>
                                                                        </ol>

                                                                        <p style={{ paddingBottom: '10px' }}>We do not use or disclose sensitive personal information for purposes that require us to offer consumers a &ldquo;Right to Limit&rdquo; the use or disclosure of sensitive personal information.</p>

                                                                        <h5>Submitting a privacy request</h5>

                                                                        <p paraeid="{ce586b11-78b1-432d-ab7e-f63bcc87b708}{71}" paraid="1927488143">If you are a resident of a state that provides privacy rights and would like to exercise a privacy right, you may submit a request by calling us at 1-877-203-2262 or using our Privacy Rights Request Form.&nbsp;</p>

                                                                        <p paraeid="{ce586b11-78b1-432d-ab7e-f63bcc87b708}{222}" paraid="246527897">After you submit your request, we may contact you to obtain additional information necessary to locate your record and/or verify your identity. For example, we may require you to verify certain information in our files or submit a signed declaration under penalty of perjury verifying your identity. We cannot&nbsp;process certain request types without verifying your identity, so please respond promptly to our request.&nbsp;</p>

                                                                        <p style={{ paddingBottom: '10px' }}><em>Cookie opt-outs&nbsp;</em></p>

                                                                        <p style={{ paddingBottom: '10px' }}>To opt-out of third-party data collections from our Sites that may qualify as sales or relate to targeted advertising, you must also (1) opt-out of applicable cookies using the &ldquo;Cookie Preferences&rdquo; link in the footer of our Sites or (2) use your browser or a browser extension to send our Sites a <a className='a-links' href="https://globalprivacycontrol.org/" rel="noreferrer noopener" target="_blank">Global Privacy</a> <a className='a-links' href="https://globalprivacycontrol.org/" rel="noreferrer noopener" target="_blank">Control</a> or <a className='a-links' href="https://fpf.org/thank-you-for-visiting-allaboutdnt-com/" rel="noreferrer noopener" target="_blank">Do Not Track</a> signal.&nbsp;</p>

                                                                        <h5>Authorized agent&nbsp;</h5>

                                                                        <p style={{ paddingBottom: '10px' }}>If you are an authorized agent submitting a request for another consumer, you must provide a copy of a lawful power of attorney or written signed authorization from the consumer (along with proof of your identity). After submitting the request, you will receive an email. You can respond to this email to&nbsp;provide the required documentation. We may contact you and/or the consumer on whose behalf you claim to act to verify your authorization.&nbsp;</p>

                                                                        <h5>Non-discrimination notice&nbsp;</h5>

                                                                        <p style={{ paddingBottom: '10px' }}>We will not discriminate against any consumer for exercising their privacy rights under law or this Policy.&nbsp;</p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div></div>
                                                    </div>

                                                </div>
                                            </div ><div className="l-grid_layout-item_in">

                                                <div className="b-accordion">

                                                </div>
                                            </div><div className="l-grid_layout-item_in">

                                                <div className="b-accordion">

                                                    <div
                                                        className="b-accordion-inner"
                                                    >
                                                        <div className="b-accordion-items"><div data-widget-event-closeallitems="closeItems" data-id="12-how-to-contact-0" data-widget="accordionItem" data-widget-event-accordionitemupdate="updateFocusedItem" className="b-accordion-item"><style>

                                                        </style>

                                                            <h3 className="b-accordion-title"
                                                                style={{}}
                                                            >
                                                                <span>Contact Us</span>
                                                                <span className="b-icon_chevron"></span>
                                                            </h3>
                                                            <div
                                                                className="b-accordion-content"
                                                                id="12-how-to-contact-0"
                                                                data-ref="accordionItemPanel"

                                                                aria-labelledby="12-how-to-contact-0-btn"
                                                            >
                                                                <div className="b-accordion-content_inner" data-ref="accordionItemPanelInner">
                                                                    <div className="b-text_block theme-font-color">
                                                                        <p style={{ paddingBottom: '10px' }}>You may contact us by calling us at 1-800- HALLMARK. We use the information you provide to respond directly to your questions or comments. If you still have questions or concerns about our privacy policy, please write us at:&nbsp;</p>

                                                                        <p style={{ paddingBottom: '10px' }}>Hallmark Consumer Care<br />
                                                                            P.O. Box 419034<br />
                                                                            Kansas City, MO 64141</p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div></div>
                                                    </div>

                                                </div>
                                            </div >
                                        </div >
                                    </div >
                                </div >
                            </div >
                        </div >
                    </div >
                </main >
            </div>
        );
    }
}

export default Privacy;