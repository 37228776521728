import React, { Component } from 'react';
import $ from "jquery";
import update from 'immutability-helper';
import { Link } from 'react-router-dom';


class SubNav extends Component {
    constructor(props) {
        super(props)

        this.state = {
            urls: [
                // { url: "/about-us/executives", title: "EXECUTIVE LEADERSHIP" },
                { url: "/about-us/history", title: "OUR HISTORY" },
                { url: "https://www.hallmarkmediainternational.com", title: "INTERNATIONAL" },
                { url: "https://www.hallmarkchannel.com/advertising", title: "ADVERTISING" },
                { url: "/about-us/contact", title: "CONTACTS" },
                { url: "https://www.hallmarkchannel.com/faq", title: "FAQ" }
            ]
        }

        this.showLinks = this.showLinks.bind(this);

    }


    showLinks(obj, i) {
        var dynamicClass = 'not-active sub-nav-li';
        if (obj.title === this.props.currentActive) {
            dynamicClass = 'active sub-nav-li'
        }

        if (obj.title === "INTERNATIONAL" || obj.title === "ADVERTISING" || obj.title === "FAQ") {
            dynamicClass += ' external-link';
            return (
                <li key={i} role="presentation" className={dynamicClass}>
                    <a className="sub-nav-a no-top-margin" target="_blank" href={obj.url}>{obj.title}</a>
                </li>
            )
        }
        else {
            return (
                <li key={i} role="presentation" className={dynamicClass}>
                    <Link className="sub-nav-a no-top-margin" to={obj.url}>{obj.title}</Link>
                </li>
            )
        }
    }

    render() {
        return (
            <div className="sub-nav">
                <div className="container">
                    <div className="">
                        <ul className="nav navbar-nav sub-navbar-nav">
                            {this.state.urls.map(this.showLinks)}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default SubNav;
