import React, { Component } from 'react';


class TextMessagingTerms extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {

        return (
            <div className='priv-container'>
                <div className="privacy-container">
                    <img className="privacy-logo" src="/images/HallmarkMedia_Logo_Plum_RGB.png" alt="Hallmark Channel Logo" />
                </div>
                <div className='b-user_content'>
                    <div className='l-static_page-region'>
                        <div className='l-static_page-item'>
                            <div className="l-grid_layout m-1_up m-centered m-padding_top-md m-padding_bottom-md  m-margin_top-none m-margin_bottom-none " >
                                <div className="l-grid_layout-content ">

                                    <div className="l-grid_layout-item m-lg_12 m-md_12 m-sm_6 m-hide_animation m-margin_bottom-md ">
                                        <div data-cell-type="lg-12 md-12 sm-6" data-layout-type="centered" className="l-grid_layout-item_in">

                                            <div className="b-header_ext m-horizontal_left m-vertical_top">
                                                <div className="b-header_ext-wrapper " style={{ maxWidth: '100%' }}>

                                                    <div className="b-text_block theme-font-color">
                                                        <h1 className="b-heading_2">Text Messaging Supplemental Terms</h1>

                                                        <p style={{ paddingBottom: "10px", paddingTop: "10px" }}>Last Updated: September 10, 2024</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div><div data-cell-type="lg-12 md-12 sm-6" data-layout-type="centered" className="l-grid_layout-item_in">

                                            <div data-slide-type="lg-4 md-4 sm-4" className="b-text_block m-horizontal_left m-vertical_top theme-font-color theme-background-color" style={{ width: '100%' }}>
                                                <div className="b-text_block-container">
                                                    <p style={{ paddingBottom: '10px' }}>Please read these terms and conditions carefully. These Text Messaging Supplemental Terms (“Text Terms”) are entered into between you and Hallmark Marketing Company, LLC, Hallmark Media United States, LLC, DaySpring Cards, Inc., and their family of companies (“Hallmark”). These Text Terms are incorporated by reference into, and are part of, the Hallmark <a className='a-links' href="/terms-of-use">Terms of Use</a>.</p>

                                                    <h4>All Text Programs/Short Codes</h4>

                                                    <p style={{ paddingBottom: '10px' }}>By clicking “submit” or replying Y or YES for any text program(s), you provide your signature expressly consenting to contact from Hallmark via text using an autodialer at the number you provided, regarding products or services from Hallmark, or its subsidiaries, affiliates, or agents. You also confirm you are the subscriber to the relevant phone number or that you are the customary user of that number on a family or business plan and you are authorized to give this consent. You understand that you are not required to enter into this agreement as a condition of any purchase. You can revoke this consent through any reasonable means including by replying STOP to a text message you receive from Hallmark. </p>

                                                    <h4>Program Descriptions</h4>

                                                    <h5>General/Marketing Text Program (Short Codes 57575 and 68856)</h5>
                                                    <p style={{ paddingBottom: '10px' }}>Hallmark and its service providers may use an automatic telephone dialing system to deliver text messages to you. Hallmark text messages in the general/marketing text program are intended to provide you with information about Hallmark’s goods and services including, but not limited to special offers, rewards, and event information. </p>

                                                    <h5>Reminders Text Program (Short Code 32223)</h5>
                                                    <p style={{ paddingBottom: '10px' }}>Hallmark and its service providers may use an automatic telephone dialing system to deliver text messages to you. Reminder text messages are intended to provide you with the reminder details for the occasions you requested and may include information about Hallmark’s goods and services relevant to that occasion.</p>

                                                    <h4>Message Frequency</h4>

                                                    <h5>General/Marketing Text Program (Short Codes 57575 and 68856)</h5>
                                                    <p style={{ paddingBottom: '10px' }}>The number of Hallmark text messages that you receive through this program will vary. You will receive a maximum of 10 Hallmark-initiated messages per month through this program/short code.</p>

                                                    <h5>Reminders Text Program (Short Code 32223)</h5>
                                                    <p style={{ paddingBottom: '10px' }}>The number of Hallmark text messages that you receive through this program will vary depending on the number of reminders you request.</p>

                                                    <h4>Cost: All Text Programs/Short Code</h4>
                                                    <p style={{ paddingBottom: '10px' }}>Message and data rates may apply to each text message sent or received in connection with Hallmark text messages, as provided in your mobile telephone service rate plan. Please contact your mobile telephone carrier for pricing plans and any applicable roaming charges.</p>

                                                    <h4>Supported Carriers: All Text Programs/Short Codes</h4>
                                                    <p style={{ paddingBottom: '10px' }}>If your mobile operator is not participating, you will not receive a reply to your messages. Some operators may not support some services (e.g. pre-paid users may not be able to participate). Check with your carrier to confirm. </p>

                                                    <h4>How to Opt-In: All Text Programs/Short Codes</h4>
                                                    <p style={{ paddingBottom: '10px' }}>To opt-in to receive text messages from a Hallmark text messaging program(s) or short code(s), please follow the instructions provided by Hallmark. Mobile numbers will not be shared between Hallmark’s text programs, so you must opt in to both programs separately if you desire to participate in both programs. </p>

                                                    <h4>How to Opt-Out: All Text Programs/Short Codes</h4>
                                                    <p style={{ paddingBottom: '10px' }}>To stop receiving text messages from a specific Hallmark text messaging program or short code, text <strong> STOP, END, QUIT, UNSUBSCRIBE, CANCEL, REVOKE, or “OPT OUT”</strong> to the five-digit short code for the text messaging program from which you no longer wish to receive message (i.e. the five digit number from which its text messages are being sent 57575, 32223, or 68856). You will then receive confirmation of your opt-out of that text messaging program. This will only opt you out of the specific text messaging program associated with that five-digit short code.</p>

                                                    <h4>Your Mobile Telephone Number: All Text Programs/Short Codes</h4>
                                                    <p style={{ paddingBottom: '10px' }}>You represent that you are the account holder for the mobile telephone number(s) that you provide. You may notify Hallmark of a number change by contacting 1-800-HALLMARK. You agree to indemnify Hallmark in full for all claims, expenses, and damages related to or caused in whole or in part by your failure to notify Hallmark if you change your telephone number, including, but not limited to, all claims, expenses, and damages related to or arising under the Telephone Consumer Protection Act, Telemarketing Sales Rule, and other applicable federal and state laws.</p>

                                                    <h4>Access or Delivery to Mobile Network is Not Guaranteed: All Text Programs/Short Codes</h4>
                                                    <p style={{ paddingBottom: '10px' }}>Delivery of information and content to a mobile device may fail due to a variety of circumstances or conditions. You understand and acknowledge that network services, including but not limited to mobile network services, are outside of Hallmark’s control, and Hallmark is not responsible or liable for issues arising from them.</p>
                                                    <p style={{ paddingBottom: '10px' }}>T-Mobile and other mobile carriers are not liable for delayed or undelivered messages.</p>

                                                    <h4>Support/Help: All Text Programs/Short Codes</h4>
                                                    <p style={{ paddingBottom: '10px' }}>To request more information about a text program, text <b>HELP</b> to five-digit short code from which its text messages are being sent (57575 or 32223). You may also receive help by contacting 1-800-HALLMARK.</p>

                                                    <h4>Eligibility: All Text Programs/Short Codes</h4>
                                                    <p style={{ paddingBottom: '10px' }}>To receive Hallmark text messages, you must be a resident of the United States and 18 years of age or older. Hallmark reserves the right to require you to prove that you are at least 18 years of age.</p>

                                                    <h4>Changes to Supplemental Terms: All Text Programs/Short Codes</h4>
                                                    <p style={{ paddingBottom: '10px' }}>Hallmark may revise, modify, or amend these Text Terms at any time. Any such revision, modification, or amendment shall take effect when it is posted to Hallmark’s website. You agree to review these Text Terms periodically to ensure that you are aware of any changes. Your continued consent to receive Hallmark text messages by not replying to a message with an opt out keyword will indicate your acceptance of those changes. </p>

                                                    <h4>Termination of Text Messaging: All Text Programs/Short Codes</h4>
                                                    <p style={{ paddingBottom: '10px' }}>Hallmark may suspend or terminate your receipt of Hallmark text messages if Hallmark believes you are in breach of these Text Terms or for any other reason. Your receipt of Hallmark text messages is also subject to termination in the event that your mobile telephone service terminates or lapses. Hallmark reserves the right to modify or discontinue, temporarily or permanently, all or any part of Hallmark text messages, with or without notice. </p>

                                                    <h4>Communications and Consent to Electronic Notices: All Text Programs/Short Codes</h4>
                                                    <p style={{ paddingBottom: '10px' }}>You may communicate with Hallmark by calling 1-800-HALLMARK. Hallmark may issue notices via various channels, including by sending e-mail to an address you provide. You agree that such notices shall have legal effect. You also agree that notices sent by e-mail satisfy any requirement that </p><p style={{ paddingBottom: '10px' }}>notices be provided in writing. If you do not agree, do not use Hallmark products or services. You may have the right to withdraw your consent to receive certain electronic communications, and, when required by law, Hallmark will provide you with paper copies upon request. You may make such a request by contacting 1-800-HALLMARK. If you withdraw your consent, Hallmark reserves the right to terminate your use of Hallmark’s products or services. By accepting these terms, you confirm that you are able to receive, access, and retain the notices that Hallmark may send. You may update your contact information by contacting 1-800-HALLMARK.</p>

                                                    <h4>Contact Us: All Text Programs/Short Codes</h4>
                                                    <p style={{ paddingBottom: '10px' }}>If you have questions regarding these Text Terms, please reach out to us by contacting 1-800-HALLMARK.</p>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        );
    }
}

export default TextMessagingTerms;