import React, { Component } from 'react';
import './css/styleDocs.css';



class RewardsTermsConditions extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {

        return (
            <div className='priv-container'>
            <div className="privacy-container">
                    <img className="privacy-logo" src="/images/HallmarkMedia_Logo_Plum_RGB.png" alt="Hallmark Channel Logo" />
                </div>
                <div className='b-user_content'>
                    <div className='l-static_page-region'>
                        <div className='l-static_page-item'>
                            <div className="l-grid_layout m-1_up m-centered m-padding_top-md m-padding_bottom-md  m-margin_top-none m-margin_bottom-none " >
                                <div className="l-grid_layout-content ">

                                    <div className="l-grid_layout-item m-lg_12 m-md_12 m-sm_6 m-hide_animation m-margin_bottom-md ">
                                        <div data-cell-type="lg-12 md-12 sm-6" data-layout-type="centered" className="l-grid_layout-item_in">

                                            <div className="b-header_ext m-horizontal_left m-vertical_top">
                                                <div className="b-header_ext-wrapper " style={{ maxWidth: '100%' }}>

                                                    <div className="b-text_block theme-font-color">

                                                        <article className="b-user_content">
                                                            <h1 className="b-heading_2">Crown Rewards Program Supplemental Terms</h1>

                                                            <p style={{ paddingBottom: "10px", paddingTop: "10px" }}>Last updated: September 10, 2024</p>

                                                            <p style={{ paddingBottom: '10px' }}>The Crown Rewards program ("Crown Rewards") is conducted by Hallmark Marketing Company, LLC ("Hallmark"). These Crown Rewards Supplemental Terms (“Crown Rewards Terms”) are entered into between you and Hallmark, and are incorporated by reference into, and are part of, the <a className='a-links' href="/terms-of-use/">Hallmark Terms of Use</a>. Hallmark reserves the right to alter, limit or modify program rules, regulations, rewards, reward levels, and membership levels or to terminate Crown Rewards or membership at any time, at its discretion.</p>

                                                            <h2 className="b-heading_3">Membership</h2>

                                                            <p style={{ paddingBottom: '10px' }}>Membership in the Crown Rewards program is free. No purchase is necessary.</p>

                                                            <p style={{ paddingBottom: '10px' }}>Crown Rewards memberships are nontransferable. To earn points, member’s account number must be confirmed at the time of purchase. Only one named member is allowed per account. Limit one membership per person. Multiple memberships tied to a single user will result in termination of Crown Rewards membership.&nbsp;</p>

                                                            <h3 className="b-heading_4">Enrollment</h3>

                                                            <p style={{ paddingBottom: '10px' }}>You may enroll in Crown Rewards in one of four ways:</p>

                                                            <ol>
                                                                <li>In-store - You may enroll in Crown Rewards by completing an application or supplying contact information to an associate in a U.S. Hallmark Gold Crown store or other location participating in the Crown Rewards program.</li>
                                                                <li>Online - You may enroll in Crown Rewards through www.hallmark.com or through the Hallmark Cards Now application. You will need to provide your email address and create a password.</li>
                                                                <li>By phone - You may enroll by calling 1-800-HALLMARK and speaking with a Consumer Care representative.</li>
                                                                <li>By purchasing Hallmark+ - If you purchase a Hallmark+ subscription and you do not already have a Crown Rewards membership, you will automatically be enrolled in Crown Rewards (exceptions may apply).</li>
                                                            </ol>

                                                            <h3 className="b-heading_4">Online Account Access</h3>

                                                            <p style={{ paddingBottom: '10px' }}>Crown Rewards members may access their account information online through an exclusive member portal. The member portal provides account information such as member number, member tier, point balance, bonus point offers, rewards available and more. Members who enroll online automatically have access to the member portal by using the email address and password used to enroll. Members who enroll in-store or by calling 1-800-HALLMARK must create an online account and link it with their Crown Rewards membership. Some additional information will be required, including your Crown Rewards member number. Members who are enrolled via their Hallmark+ membership may need to opt-in to Crown Rewards; to do so, sign-in to Hallmark.com using Hallmark+ credentials and visit the account “Dashboard.”</p>

                                                            <h3 className="b-heading_4">Membership Cancellation</h3>

                                                            <p style={{ paddingBottom: '10px' }}>Crown Rewards members may cancel their membership at any time by calling 1-800-HALLMARK.</p>

                                                            <h2 className="b-heading_3">Earning Points</h2>

                                                            <p style={{ paddingBottom: '10px' }}>Points are earned on purchases made in a U.S. Hallmark Gold Crown store, on Hallmark.com, at 1-800-HALLMARK, or other locations participating in the Crown Rewards program. Crown Rewards members earn 10 points for every $1 spent.</p>

                                                            <p style={{ paddingBottom: '10px' }}>We may also provide special opportunities to earn additional points for taking certain actions or making purchases on specific days. These special opportunities will be announced in advance by a post on our website, an email announcement, or other advertisement.</p>

                                                            <p style={{ paddingBottom: '10px' }}>Members who earn 2,500 points in a calendar year will achieve Platinum status.&nbsp; Platinum Members earn 25% more points on every dollar spent and may enjoy additional benefits and promotional offers. Members with a linked Hallmark+ subscription receive up to 50% more points on every dollar spent and enjoy additional benefits. Hallmark+ members should refer to the Hallmark+ Supplemental Terms.</p>

                                                            <p style={{ paddingBottom: '10px' }}>Purchases for which points are not earned include, but are not limited to: gift cards, Business Greeting Cards, postage stamps, services, Hallmark.com shipping charges, subscriptions, office supplies, pharmaceuticals, any single non-Hallmark item the price of which exceeds $500 and other items as designated at each retailer’s discretion. Points are not awarded on any portion of the transaction (sale) paid for with Gift Cards or Reward/Bonus Dollars or any tax paid on purchases. Points themselves are not redeemable; only Reward Dollars are redeemable.</p>

                                                            <p style={{ paddingBottom: '10px' }}>When a purchased item on which points were earned is returned for any reason, points earned for the purchase will be deducted from the account to which the points were credited.</p>

                                                            <h2 className="b-heading_3">Rewards</h2>

                                                            <p style={{ paddingBottom: '10px' }}>Points may be converted into Reward Dollars once a member has earned 600 points in a given calendar year. The timing and delivery of the issuance of Reward Dollars will be at Hallmark’s discretion.</p>

                                                            <p style={{ paddingBottom: '10px' }}>Reward Dollars are valid for the length of time indicated on the reward.</p>

                                                            <p style={{ paddingBottom: '10px' }}>When total points earned are less than 600 in a given calendar year, the member’s entire point balance will reset to 0 in the first quarter of the following year. This provision is not applicable to members until their first full calendar year of enrollment in the program. Any points earned by the member prior to his or her first full calendar year of enrollment in the program will be carried over to the first full calendar year.</p>

                                                            <p style={{ paddingBottom: '10px' }}>Reward Dollars can be redeemed for all products offered for sale at U.S. Hallmark Gold Crown stores, on Hallmark.com, at 1-800-HALLMARK, or other locations participating in the Crown Rewards program, subject to the following exclusions:&nbsp; previous purchases, layaway purchases,&nbsp; gift cards, postage stamps, business greeting cards, subscriptions, office supplies, pharmaceuticals, shipping charges and other exclusions imposed at the discretion of the redeeming retailer.&nbsp; Reward Dollars can only be redeemed for the exact amount shown on the front of each certificate at time of purchase. No cash value, credit or change will be given. Customer is responsible for any applicable sales tax and shipping and/or handling fees. Reward Dollars may be combined with other Hallmark Reward dollars and offers.&nbsp; Reward dollars cannot be sold, reproduced or redeemed for cash.</p>

                                                            <p style={{ paddingBottom: '10px' }}>Hallmark+ members receive additional benefits and should refer to the Hallmark+ Supplemental Terms to learn more.</p>

                                                            <h2 className="b-heading_3">General</h2>

                                                            <p style={{ paddingBottom: '10px' }}>Crown Rewards is only available in U.S. Hallmark Gold Crown stores, on Hallmark.com, at other locations participating in the Crown Rewards program or by calling 1-800-HALLMARK. Hallmark does not ship to AFO/FPO/DPO addresses.</p>

                                                            <p style={{ paddingBottom: '10px' }}>Hallmark reserves the right to cancel and/or terminate any membership that, in Hallmark’s sole discretion, is fraudulent or seeks to abuse or violate the purpose and intent of the program.</p>

                                                            <p style={{ paddingBottom: '10px' }}>For Questions:</p>

                                                            <p style={{ paddingBottom: '10px' }}>Visit <a className='a-links' href="https://corporate.hallmarkmedia.com/crown-rewards-terms-conditions" rel="noopener">Crown Rewards</a> or call 1-800-HALLMARK.</p>

                                                            <p style={{ paddingBottom: '10px' }}>You can view Hallmark’s current privacy policy, including the Notice of Financial Incentive related to Crown Rewards, at <a className='a-links' href="https://corporate.hallmarkmedia.com/privacy/" target="_blank">www.Hallmark.com/privacy</a>.</p>
                                                        </article>

                                                        <p>&nbsp;</p>

                                                        <p>&nbsp;</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
        );
    }
}

export default RewardsTermsConditions;